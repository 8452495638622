import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement,
  SimpleGrid,
  Spinner,
  useColorModeValue,
} from '@chakra-ui/react'
import { useRef, useState } from 'react'
import { FiSearch } from 'react-icons/fi'
import useClickOutside from '../../../../hooks/useClickOutside'
import useSearchOwner, { useSearchOwnerSearchResult } from '../../../../hooks/useSearchOwner'
import getOnlyNumbers from '../../../../services/utils/getOnlyNumbers'

type OwnerSearchOptionProps = {
  name: string
} & ButtonProps

const OwnerSearchOption = ({ name, ...rest }: OwnerSearchOptionProps): JSX.Element => (
  <Button
    rounded="none"
    fontWeight="normal"
    fontSize="14"
    justifyContent="start"
    size="md"
    w="100%"
    {...rest}
  >
    <Box>{name}</Box>
  </Button>
)

type OwnerSearchInputProps = {
  isLoading?: boolean
} & InputProps

const OwnerSearchInput = ({ isLoading, ...rest }: OwnerSearchInputProps): JSX.Element => (
  <InputGroup size="sm">
    <InputLeftElement pointerEvents="none">
      <FiSearch color="gray.300" />
    </InputLeftElement>
    <Input {...rest} />
    <InputRightElement>{isLoading && <Spinner size="sm" />}</InputRightElement>
  </InputGroup>
)

const OwnerSearchContainer = (props: BoxProps): JSX.Element => <Box {...props} position="relative" />

export type onSelectOwnerSearchResult = useSearchOwnerSearchResult

type OwnerSearchProps = {
  onSelect?: (data: useSearchOwnerSearchResult) => void
}

export const OwnerSearch = ({ onSelect }: OwnerSearchProps): JSX.Element => {
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [data, setData] = useState<useSearchOwnerSearchResult>()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)
  const bg = useColorModeValue('gray.100', 'gray.800')

  const { loading, search } = useSearchOwner({
    onSuccess: result => {
      setData(result)
    },
  })

  const handleChange = (value: string) => {
    setSearchQuery(value)
    search(value, getOnlyNumbers(value).length === 11 ? 'pf' : 'pj')
    setIsDropdownOpen(true)
  }

  useClickOutside(containerRef, () => {
    if (isDropdownOpen) {
      setIsDropdownOpen(false)
      setSearchQuery('')
      setData(undefined)
    }
  })

  return (
    <OwnerSearchContainer w="100%">
      <OwnerSearchInput
        value={searchQuery}
        onChange={e => handleChange(e.target.value)}
        isLoading={loading}
        placeholder="Pesquisar proprietário por CPF ou CNPJ..."
      />
      {isDropdownOpen && (
        <SimpleGrid
          columns={1}
          my={2}
          w="100%"
          zIndex={10}
          py={2}
          bg={bg}
          position="absolute"
          rounded="md"
          shadow="md"
          maxH="200px"
          overflowY="auto"
          gap={2}
          ref={containerRef}
        >
          {data?.owner ? (
            <OwnerSearchOption
              key={data.owner?.id}
              name={data.owner?.name || data.cpnj_person?.name || ''}
              onClick={() => {
                onSelect?.(data)
                setIsDropdownOpen(false)
                setSearchQuery('')
                setData(undefined)
              }}
            />
          ) : (
            <Box textAlign="center" p={2}>
              Nenhum proprietário encontrado
            </Box>
          )}
        </SimpleGrid>
      )}
    </OwnerSearchContainer>
  )
}
