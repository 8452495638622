import { useColorModeValue } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import 'leaflet/dist/leaflet.css'

import { Layout } from '../../../layout'
import { OwnerInfo } from './owner-info'
import { OwnerVehicle } from './vehicle-info'
import { OwnerAttachments } from './attachments'

interface RequestParams {
  owner_id: string
}

export function ShowOwner(): JSX.Element {
  const bg = useColorModeValue('white', 'gray.800')
  const { owner_id } = useParams<RequestParams>()

  return (
    <Layout>
      <OwnerInfo owner_id={owner_id} bg={bg} />
      <OwnerVehicle owner_id={owner_id} bg={bg} />
      <OwnerAttachments owner_id={owner_id} bg={bg} />
    </Layout>
  )
}
