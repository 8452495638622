import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import { apiServer } from '../../../api'
import { ResponseApiType } from '../../../types'

type DeleteLoadingRequestParams = {
  id: string
}

type ResponseApi = {
  data: boolean
}

async function deleteLoadingRequest(params: DeleteLoadingRequestParams): Promise<ResponseApi> {
  const { data: response } = await apiServer.delete<ResponseApiType<ResponseApi>>('loading-requests', {
    params: {
      ...params,
    },
  })

  return response.data
}

export function useDeleteLoadingRequest(
  options?: UseMutationOptions<ResponseApi, unknown, DeleteLoadingRequestParams, unknown>,
): UseMutationResult<ResponseApi, unknown, DeleteLoadingRequestParams, unknown> {
  return useMutation(async (params: DeleteLoadingRequestParams) => deleteLoadingRequest(params), options)
}
