import { format } from 'date-fns'
import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../../api'
import { LoadingRequestType } from '../../../types/LoadingRequestType'
import { formatLoadingRequestStatus } from '../../../utils/formatLoadingRequestStatus'
import parseCurrency from '../../../utils/parseCurrency'

export type LoadingRequestTypeFormatted = LoadingRequestType & {
  formatted_status: string
  total_value_formatted: string
  total_gross_formatted: string
  discount_buonny_formatted: string
}

type FindLoadingRequestResponse = LoadingRequestTypeFormatted

export type FilterLoadingRequestsType = {
  id: string
}

async function findLoadingRequest(params: FilterLoadingRequestsType) {
  const { data: response } = await apiServer.get(`/loading-requests/find`, {
    params,
  })

  const { data } = response

  return {
    ...data,
    discount_buonny_formatted: parseCurrency(data.discount_buonny),
    total_value_formatted: parseCurrency(data.total_value),
    total_gross_formatted: parseCurrency(data.total_gross),
    formatted_status: formatLoadingRequestStatus(data.status),
    created_at: format(new Date(data.created_at), 'dd/MM/yy HH:mm'),
  }
}

export function useGetLoadingRequest(
  params: FilterLoadingRequestsType,
): UseQueryResult<FindLoadingRequestResponse> {
  return useQuery(['loading-requests', params], () => findLoadingRequest(params), {
    staleTime: 1000 * 60 * 10,
  })
}
