import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import { apiServer } from '../../api'
import { ResponseApiType } from '../../types'

type DeleteCteIssuanceRequestParams = {
  id: string
}

type ResponseApi = {
  data: boolean
}

async function deleteCteIssuanceRequest(params: DeleteCteIssuanceRequestParams): Promise<ResponseApi> {
  const { data: response } = await apiServer.delete<ResponseApiType<ResponseApi>>('cte-issuance-requests', {
    params: {
      ...params,
    },
  })

  return response.data
}

export function useDeleteCteIssuanceRequest(
  options?: UseMutationOptions<ResponseApi, unknown, DeleteCteIssuanceRequestParams, unknown>,
): UseMutationResult<ResponseApi, unknown, DeleteCteIssuanceRequestParams, unknown> {
  return useMutation(
    async (params: DeleteCteIssuanceRequestParams) => deleteCteIssuanceRequest(params),
    options,
  )
}
