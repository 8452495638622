import {
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react'
import { AiOutlineClose } from 'react-icons/ai'
import { useHistory, useParams } from 'react-router-dom'
import { toastError } from '../../../../../config/error/toastError'
import { useDeleteAdvanceRequest } from '../../../../../services/endpoints/freights/deleteAdvanceRequest'

type DeleteAdvanceRequestModalProps = {
  isOpen: boolean
  onClose: () => void
}

type RequestParams = {
  id: string
}

export function DeleteAdvanceRequestModal({ isOpen, onClose }: DeleteAdvanceRequestModalProps): JSX.Element {
  const toast = useToast()
  const { id } = useParams<RequestParams>()
  const history = useHistory()

  const { mutateAsync: deleteAdvanceRequest, isLoading: isAdvanceDeleteAdvanceRequest } =
    useDeleteAdvanceRequest({
      onSuccess: () => {
        toast({
          title: 'Solicitação de adiantamento excluida com sucesso!',
          status: 'success',
          position: 'top-right',
          isClosable: true,
          duration: 4000,
        })

        history.push('/advance-requests')
      },
      onError: error => {
        toastError({ toast, error })
      },
    })

  async function handleDeleteAdvanceRequest(): Promise<void> {
    if (id) {
      await deleteAdvanceRequest({ id })
    }
  }

  return (
    <Modal
      size="2xl"
      isOpen={isOpen}
      onClose={() => {
        onClose()
      }}
      isCentered
      closeOnOverlayClick
    >
      <ModalOverlay />

      <ModalContent>
        <ModalCloseButton />

        <ModalBody>
          <Text fontWeight="bold" fontSize="20">
            Deseja realmente deletar a solicitação de adiantamento?
          </Text>
          <Text color="gray.400" mt={2} mb={2}>
            Se a solicitação de adiantamento for deletada todos os dados relacionados a ela também serão
            deletados.
          </Text>

          <Flex justifyContent="flex-end" mt={2}>
            <Button variant="ghost" colorScheme="red" mr={3} onClick={onClose}>
              Cancelar
            </Button>
            <Button
              type="submit"
              colorScheme="red"
              onClick={() => {
                handleDeleteAdvanceRequest()
              }}
              isAdvance={isAdvanceDeleteAdvanceRequest}
            >
              <Icon fontSize={16} as={AiOutlineClose} mr={1} /> Deletar
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
