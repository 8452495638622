import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import * as yup from 'yup'
import { SelectOption } from '../../../../../components/form'
import { toastError } from '../../../../../config/error/toastError'
import { CteIssuanceRequestTypeFormatted } from '../../../../../services/endpoints/freights/GetCteIssuanceRequest'
import { usePutCteIssuanceRequest } from '../../../../../services/endpoints/freights/PutCteIssuanceRequest'
import { CteIssuanceRequestStatusEnum } from '../../../../../services/types/EnumTypes'
import { CreateOrUpdateCteIssuanceRequestForm } from '../forms/CreateOrUpdateCteIssuanceRequestForm'

type UpdateCteIssuanceRequestModalProps = {
  isOpen: boolean
  onClose: () => void
  initial_value: CteIssuanceRequestTypeFormatted
}

type RequestParams = {
  id: string
}

type UpdateCteIssuanceRequestType = {
  motorist: SelectOption
  motorist_vehicle_id: string
  has_toll?: string
  toll_value?: number
  deduct_motorist_total?: string
  observation?: string
  tag_toll?: string
  discount_buonny?: number
}

const UpdateCteIssuanceRequestSchema = yup.object().shape({
  motorist: yup.object().shape({}).required('Campo obrigatório'),
  motorist_vehicle_id: yup.string().required('Campo obrigatório'),
  has_toll: yup.string(),
  toll_value: yup.string(),
  deduct_motorist_total: yup.string(),
  tag_toll: yup.string(),
  discount_buonny: yup.string().required('Campo obrigatório'),
  observation: yup.string(),
})

export function UpdateCteIssuanceRequestModal({
  isOpen,
  onClose,
  initial_value,
}: UpdateCteIssuanceRequestModalProps): JSX.Element {
  const toast = useToast()
  const { handleSubmit, setValue, formState } = useForm({
    resolver: yupResolver(UpdateCteIssuanceRequestSchema),
  })

  const { errors } = formState

  const { id } = useParams<RequestParams>()

  const { mutateAsync: updateCteIssuanceRequest, isLoading: isLoadingUpdateCteIssuanceRequest } =
    usePutCteIssuanceRequest({
      onSuccess: () => {
        toast({
          title: 'Solicitação de emissão de CTe atualizada com sucesso!',
          status: 'success',
          position: 'top-right',
          isClosable: true,
          duration: 4000,
        })

        onClose()
      },
      onError: error => {
        toastError({ toast, error })
      },
    })

  const handleUpdateCteIssuanceRequest: SubmitHandler<UpdateCteIssuanceRequestType> = async data => {
    const updateCteIssuanceRequestData = {
      status: CteIssuanceRequestStatusEnum.AWAITING_ANALYSIS,
      motorist_id: data.motorist?.value,
      motorist_vehicle_id: data.motorist_vehicle_id,
      observation: data.observation,
      has_toll: data.has_toll === 'yes',
      toll_value: Number(data.toll_value),
      deduct_motorist_total: data.deduct_motorist_total === 'yes',
      tag_toll: data.tag_toll,
      discount_buonny: Number(data.discount_buonny),
    }

    await updateCteIssuanceRequest({
      id,
      data: updateCteIssuanceRequestData,
    })
  }

  return (
    <Modal
      size="5xl"
      isOpen={isOpen}
      onClose={() => {
        onClose()
      }}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>Atualizar solicitação de emissão de CTe</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(handleUpdateCteIssuanceRequest)} noValidate>
          <ModalBody>
            <CreateOrUpdateCteIssuanceRequestForm
              setValue={setValue}
              errors={errors}
              initial_value={initial_value}
            />
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" colorScheme="red" mr={3} onClick={onClose}>
              Cancelar
            </Button>
            <Button
              type="submit"
              colorScheme="orange"
              isLoading={isLoadingUpdateCteIssuanceRequest}
              isDisabled={isLoadingUpdateCteIssuanceRequest}
            >
              Atualizar
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}
