import {
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { format } from 'date-fns'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { RiCheckLine } from 'react-icons/ri'
import { toastError } from '../../../../config/error/toastError'
import { queryClient } from '../../../../config/react-query'
import { useAbility } from '../../../../hooks/useAbility'
import {
  createFreightStatusHistory,
  useCreateFreightStatusHistory,
} from '../../../../services/endpoints/freights/postFreightStatusHistory'
import { FreightStatusHistoryType } from '../../../../services/types'
import { Ability } from '../../../ability'
import { Input } from '../../../form/Input'

interface DateStatusProps {
  history?: FreightStatusHistoryType
}
export function EditStatusDateModal({ history }: DateStatusProps): JSX.Element | null {
  const cannotCreateFreightStatusHistory = !useAbility('operation', 'create-freight-status-history')
  const toast = useToast()
  const {
    isOpen: isEditStatusDateOpen,
    onClose: onEditStatusDateClose,
    onOpen: onEditStatusDateOpen,
  } = useDisclosure()
  const { handleSubmit, setValue } = useForm({})
  const [statusDate, setStatusDate] = useState(String(history?.status_date))

  const { mutateAsync: postFreightStatusHistory, isLoading } = useCreateFreightStatusHistory({
    onSuccess: () => {
      queryClient.invalidateQueries('freight-status-history')
      queryClient.invalidateQueries('freight')
    },
  })

  const handleCreateFreightStatusHistory = async (data: createFreightStatusHistory) => {
    try {
      await postFreightStatusHistory(data)
      toast({
        title: 'Data do status alterada com sucesso!',
        status: 'success',
        duration: 5000,
        position: 'top-right',
        isClosable: true,
      })
      onEditStatusDateClose()
    } catch (error) {
      toastError({ toast, error })
    }
  }

  return history ? (
    <Flex justify="space-between" gridGap="2" direction={['column', 'column', 'row']}>
      <Ability module="operation" action="create-freight-status-history">
        <Button size="xs" fontWeight="normal" colorScheme="orange" onClick={onEditStatusDateOpen}>
          Editar data
        </Button>
      </Ability>

      <Modal
        isOpen={isEditStatusDateOpen}
        onClose={onEditStatusDateClose}
        isCentered
        closeOnOverlayClick={false}
        size="sm"
      >
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={handleSubmit(handleCreateFreightStatusHistory)}>
            <ModalHeader>Alterar data do status</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Input
                type="datetime-local"
                setValue={setValue}
                onChange={e => setStatusDate(e.target.value)}
                name="status_date"
                label="Data"
                initialValue={
                  history.status_date && format(new Date(history.status_date), "yyyy-MM-dd'T'HH:mm:ss")
                }
              />
            </ModalBody>

            <ModalFooter>
              <Button variant="ghost" colorScheme="red" mr={3} onClick={onEditStatusDateClose}>
                Cancelar
              </Button>

              <Button
                isDisabled={cannotCreateFreightStatusHistory}
                onClick={() => {
                  handleCreateFreightStatusHistory({
                    freight_id: history.freight_id,
                    status_date: statusDate,
                    status: history.status,
                  })
                }}
                colorScheme="orange"
                leftIcon={<Icon as={RiCheckLine} />}
                isLoading={isLoading}
              >
                Alterar
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </Flex>
  ) : null
}
