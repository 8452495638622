import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import { apiServer } from '../../api'
import { ResponseApiType } from '../../types'

type DeleteAdvanceRequestParams = {
  id: string
}

type ResponseApi = {
  data: boolean
}

async function deleteAdvanceRequest(params: DeleteAdvanceRequestParams): Promise<ResponseApi> {
  const { data: response } = await apiServer.delete<ResponseApiType<ResponseApi>>('advance-requests', {
    params: {
      ...params,
    },
  })

  return response.data
}

export function useDeleteAdvanceRequest(
  options?: UseMutationOptions<ResponseApi, unknown, DeleteAdvanceRequestParams, unknown>,
): UseMutationResult<ResponseApi, unknown, DeleteAdvanceRequestParams, unknown> {
  return useMutation(async (params: DeleteAdvanceRequestParams) => deleteAdvanceRequest(params), options)
}
