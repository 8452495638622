import { Box, Table, Tbody, Td, Th, Thead, Tr, Flex, Heading, Button, Icon, Link } from '@chakra-ui/react'
import { useState } from 'react'
import { useEffect } from 'react'
import { RiDownload2Line } from 'react-icons/ri'
import { useGetOwner } from '../../../../services/endpoints/owners/getOwner'
import { OwnerAttachmentType } from '../../../../services/types/OwnerAttachmentType'

interface OwnerAttachmentsProps {
  owner_id: string
  bg: string
}

export function OwnerAttachments({ owner_id, bg }: OwnerAttachmentsProps): JSX.Element {
  const [attachments, setAttachments] = useState<OwnerAttachmentType[]>()

  const { data: fetchedOwner } = useGetOwner(owner_id)

  useEffect(() => {
    if (fetchedOwner && fetchedOwner.attachments) {
      setAttachments(fetchedOwner.attachments)
    }
  }, [fetchedOwner])

  return (
    <Box bg={bg} p="6" borderRadius="8" shadow="md" mt="8">
      <Flex mb={['6', '8']} justify="space-between" align="center">
        <Heading size="lg" fontWeight="normal">
          Anexos
        </Heading>
      </Flex>

      <Table d={['block', 'block', 'table']} overflowX="auto">
        <Thead>
          <Tr>
            <Th>Tipo</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {attachments &&
            attachments.length > 0 &&
            attachments.map(attachment => (
              <Tr key={attachment.id}>
                <Td>{attachment.type}</Td>
                <Td textAlign="right">
                  {attachment.attachment_file_url && (
                    <Button
                      as={Link}
                      href={attachment.attachment_file_url}
                      target="_blank"
                      rel="noreferrer"
                      size="sm"
                      variant="outline"
                      colorScheme="orange"
                      leftIcon={<Icon as={RiDownload2Line} />}
                    >
                      Baixar
                    </Button>
                  )}
                </Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
    </Box>
  )
}
