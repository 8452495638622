import { FormattedMotorist } from '../../../../services/endpoints/motorists/getMotorist'
import OwnerType from '../../../../services/types/OwnerType'
import getOnlyNumbers from '../../../../services/utils/getOnlyNumbers'

type InputValidate = {
  owner: OwnerType | null | undefined
  motorist: FormattedMotorist | null | undefined
}

export const isMissingDocOwner = ({ owner, motorist }: InputValidate): boolean => {
  const hasAttachment = owner?.attachments?.find(attachment => attachment.type === 'cnh')
  if (
    motorist?.cpf &&
    owner?.cpf &&
    owner.type === 'pf' &&
    getOnlyNumbers(motorist.cpf) !== getOnlyNumbers(owner.cpf) &&
    !hasAttachment
  ) {
    return true
  }
  return false
}
