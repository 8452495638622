import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import { apiServer } from '../../api'
import { ResponseApiType } from '../../types'

type DeleteBalanceRequestParams = {
  id: string
}

type ResponseApi = {
  data: boolean
}

async function deleteBalanceRequest(params: DeleteBalanceRequestParams): Promise<ResponseApi> {
  const { data: response } = await apiServer.delete<ResponseApiType<ResponseApi>>('balance-requests', {
    params: {
      ...params,
    },
  })

  return response.data
}

export function useDeleteBalanceRequest(
  options?: UseMutationOptions<ResponseApi, unknown, DeleteBalanceRequestParams, unknown>,
): UseMutationResult<ResponseApi, unknown, DeleteBalanceRequestParams, unknown> {
  return useMutation(async (params: DeleteBalanceRequestParams) => deleteBalanceRequest(params), options)
}
