import { Alert, AlertIcon, AlertTitle, Flex, useToast } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback, useEffect, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { RiUser2Fill } from 'react-icons/ri'
import { AlertModal } from '../../../../components/AlertModal'
import { toastError } from '../../../../config/error/toastError'
import { queryClient } from '../../../../config/react-query'
import { apiServer } from '../../../../services/api'
import { useIsBlockEntity } from '../../../../services/endpoints/blocked-entities/isBlockEntity'
import { ownerPayload, useCreateOwner } from '../../../../services/endpoints/owners/createOwner'
import { useCreateAttachmentToOwner } from '../../../../services/endpoints/owners/owners-attachments/createVehicleAttachment'
import { usePutVehicle } from '../../../../services/endpoints/vehicles/putVehicle'
import OwnerType from '../../../../services/types/OwnerType'
import getOnlyNumbers from '../../../../services/utils/getOnlyNumbers'
import { useAppDispatch, useAppSelector } from '../../../../store'
import {
  setDriverLicenseFile,
  setDriverLicenseOwnerImg,
  setIsNewImage,
} from '../../../../store/slices/ocrCnhSlice'
import { verifyIfDataByMotorist } from '../utils/validations'
import ChooseOwner from '../components/ChooseOwner'
import { DrawerWrapper } from '../components/DrawerWrapper'
import { OwnerSearch } from '../components/OwnerSearch'
import {
  AnttForm,
  anttFormInitialValues,
  AnttFormValues,
  AnttOnwerSchema,
} from '../../../../components/forms/antt'
import { createCNHInitialValues, createOwnerAnttInitialValues } from '../utils'
import UploadCNH from './UploadCnh.modal'
import { isMissingDocOwner } from '../utils/isMissingDocOwner'

type AnttModalProps = {
  isOpen: boolean
  onClose: () => void
}

/**
 * Modal component for handling ANTT owner-related actions such as linking or unlinking a vehicle owner.
 */
const AnttModalForm = ({ isOpen, onClose }: AnttModalProps): JSX.Element => {
  const [anttOwner, setAnttOwner] = useState<OwnerType | null>(null)
  const { vehicle, motorist } = useAppSelector(state => state.freightPendenciesSlice)
  const { driverLicenseFile, isNewImage } = useAppSelector(state => state.ocrCnhSlice)
  const toast = useToast()
  const dispatch = useAppDispatch()

  const invalidateQueries = () => {
    queryClient.invalidateQueries('vehicle')
    queryClient.invalidateQueries('check-pendencies')
    queryClient.invalidateQueries('freight')
  }

  // Form methods using React Hook Form
  const methods = useForm<AnttFormValues>({
    mode: 'onChange',
    defaultValues: anttFormInitialValues,
    resolver: yupResolver(AnttOnwerSchema),
  })

  const {
    handleSubmit,
    formState: { isDirty, isSubmitting },
    getValues,
    reset,
  } = methods

  const showSuccessToast = (message: string) => {
    toast({
      status: 'success',
      title: message,
      position: 'top-right',
      duration: 8000,
      isClosable: true,
    })
  }

  const createOwner = useCreateOwner({
    onError: errorReq => {
      toastError({
        error: errorReq,
        toast,
      })
    },
  })

  const createAttachment = useCreateAttachmentToOwner({
    onSuccess: () => {
      showSuccessToast('Anexo salvo com sucesso!')
      dispatch(setIsNewImage(false))
      dispatch(setDriverLicenseFile({} as File))
    },
    onError: errorReq => {
      toastError({
        error: errorReq,
        toast,
      })
    },
  })

  const updateVehicle = usePutVehicle({
    onSuccess: () => {
      showSuccessToast('cadastro do frete atualizado!')
      invalidateQueries()
    },
    onError: errorReq => {
      toastError({
        error: errorReq,
        toast,
      })
    },
  })

  // Get data for blocked entities
  const { data: blockedEntity } = useIsBlockEntity({
    vehicle_owner_id: anttOwner?.id,
    trailer_owner_id: anttOwner?.id,
  })

  // Function to show a warning toast when linking an owner to a vehicle
  const notifyOnlyLinkOwnerToVehicle = (): void => {
    toast({
      title:
        'Proprietário está em análise e portanto não foi editado, apenas vinculado. Para alterações solicite correção da análise de risco.',
      isClosable: true,
      status: 'warning',
      position: 'top',
      duration: 1000 * 8,
    })
  }

  // Function to link owner to vehicle
  async function linkOwnerToVehicle(antt_owner_id: string): Promise<void> {
    if (vehicle && antt_owner_id && vehicle?.antt_owner_id !== antt_owner_id) {
      await updateVehicle.mutateAsync({
        id: vehicle.id,
        antt_owner_id,
        disable_entities_block: true,
      })
    }
  }

  // Function to unlink owner from vehicle
  async function unlinkOwnerFromVehicle() {
    if (!vehicle) return
    try {
      await updateVehicle.mutateAsync({
        id: vehicle.id,
        antt_owner_id: null as any,
        disable_entities_block: true,
      })
      reset(anttFormInitialValues)
      setAnttOwner(null)
    } catch (error) {
      toastError({ toast, error })
    }
  }

  async function attachDoc(Owner?: OwnerType): Promise<void> {
    if (driverLicenseFile.name && Owner?.id && anttOwner) {
      const hasAttachment = anttOwner?.attachments?.find(attachment => attachment.type === 'cnh')
      await createAttachment.mutateAsync({
        ...(hasAttachment?.id ? { id: hasAttachment.id } : {}),
        attachment_file: driverLicenseFile,
        owner_id: Owner.id,
        type: 'cnh',
      })
    } else if (isMissingDocOwner({ motorist, owner: Owner })) {
      toast({
        title: 'Erro ao completar o cadastro do proprietário!',
        description:
          'CNH obrigatória para proprietários do tipo PF que não sejam motoristas! Anexe uma CNH para seguir com o cadastro.',
        status: 'error',
        position: 'top',
        duration: 15 * 1000, // 10 seconds
        isClosable: true,
      })
      throw new Error('CNH obrigatória para proprietários do tipo PF que não sejam motoristas')
    }
  }

  const onSubmit: SubmitHandler<AnttFormValues> = async data => {
    if (blockedEntity && anttOwner) {
      try {
        await linkOwnerToVehicle(anttOwner.id)
        notifyOnlyLinkOwnerToVehicle()
        invalidateQueries()
        onClose()
      } catch (error) {
        toastError(error)
      } finally {
        reset(data)
      }
      return
    }
    try {
      const payload = {
        type: data.type,
        name: data.name,
        ...(data.type === 'pf'
          ? {
              birth: data.birth,
              cpf: getOnlyNumbers(data.document),
              rg: data.rg_ie,
              pis: data.pis,
              rg_dispatch_date: data.rg_dispatch_date,
              rg_dispatcher: data.rg_dispatcher,
              rg_uf: data.rg_uf,
              father_name: data.father_name,
              mother_name: data.mother_name,
            }
          : {
              cnpj: getOnlyNumbers(data.document),
              ie: data.rg_ie,
            }),
        phone: getOnlyNumbers(data.phone),
        address: data.address,
        address_number: data.address_number,
        district: data.district,
        city_id: data?.city.value,
        zipcode: getOnlyNumbers(data.zipcode),
        rntrc: data.rntrc,
        rntrc_type: data.rntrc_type,
        account: data.account,
        account_type: data.account_type,
        agency: data.agency,
        bank: data.bank?.value,
      } as ownerPayload

      if (anttOwner) {
        const isOwnerMotoristToo = verifyIfDataByMotorist(anttOwner)
        if (isOwnerMotoristToo) {
          try {
            const { data: response } = await apiServer.post('/create-owner-by-motorist', {
              motorist_id: anttOwner?.id,
              ...payload,
            })
            await attachDoc(response.data)
            await linkOwnerToVehicle(response.data.id)
            showSuccessToast('Cadastro ANTT efetuado com sucesso')
            invalidateQueries()
            onClose()
          } catch (error) {
            toastError({ toast, error })
          } finally {
            reset(data)
          }
          return
        }
      }
      if (anttOwner) {
        const owner_antt_id = anttOwner.id
        await createOwner.mutateAsync({
          ...payload,
          id: owner_antt_id,
        })
        await attachDoc(anttOwner)
        await linkOwnerToVehicle(anttOwner.id)
        showSuccessToast('Atualizado cadastrado ANTT com sucesso')
      } else {
        const { data: ownerCreated } = await createOwner.mutateAsync({
          ...payload,
        })
        await attachDoc(ownerCreated)
        await linkOwnerToVehicle(ownerCreated.id)
        showSuccessToast('Cadastro ANTT efetuado com sucesso')
      }
      invalidateQueries()
      onClose()
    } catch (error) {
      toastError(error)
    } finally {
      reset(data)
    }
  }

  const handleClose = () => {
    if (isDirty) {
      toast({
        title: 'Alterações não salvas',
        description: 'Por favor salve as alterações antes de fechar ou descarte as alterações.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      })
      return
    }

    if (isNewImage || driverLicenseFile.name) {
      toast({
        title: 'Alterações não salvas, nova CRLV',
        description: 'Por favor salve as alterações antes de fechar ou descarte as alterações.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      })
      return
    }
    onClose()
  }

  const crateInitialValues = useCallback(
    (owner: OwnerType) => {
      const { attachments } = owner
      dispatch(
        setDriverLicenseOwnerImg(attachments?.find(atch => atch.type === 'cnh')?.attachment_file_url || ''),
      )
      reset(createOwnerAnttInitialValues(owner))
    },
    [reset, dispatch],
  )

  useEffect(() => {
    if (vehicle) {
      const antt = vehicle.antt_owner
      if (antt) {
        setAnttOwner(antt)
        crateInitialValues(antt)
      }
    }
  }, [vehicle, reset, crateInitialValues])

  return (
    <>
      <DrawerWrapper
        isOpen={isOpen}
        onClose={handleClose}
        onForceClose={() => {
          onClose()
          dispatch(setDriverLicenseFile({} as File))
          dispatch(setIsNewImage(false))
        }}
        title="ANTT"
        onSave={() => handleSubmit(onSubmit)()}
        isSubmitting={isSubmitting}
        showForceClose={isDirty}
      >
        <OwnerSearch
          onSelect={data => {
            if (data.owner) {
              crateInitialValues(data.owner)
              setAnttOwner(data.owner)
            }
          }}
        />

        {isMissingDocOwner({ motorist, owner: vehicle?.antt_owner }) && (
          <Alert variant="subtle" status="error" borderRadius="md">
            <AlertIcon />
            <AlertTitle mr={2} fontSize="sm">
              CNH obrigatória para proprietários da ANTT do tipo PF que não sejam o motorista! Anexe uma CNH
              para seguir com o cadastro.
            </AlertTitle>
          </Alert>
        )}

        <UploadCNH
          type="owner"
          onSaveInformations={data => {
            reset({
              ...getValues(),
              ...createCNHInitialValues(data),
            })
          }}
        />

        <Flex flexDirection={{ base: 'column', md: 'row' }} justify="space-between" gridGap={2} my={4}>
          <ChooseOwner
            onOwnerFound={data => {
              const hasValues = Object.values(data)

              if (hasValues.length) {
                setAnttOwner(data)
                crateInitialValues(data)
              } else {
                reset(anttFormInitialValues)
              }
            }}
          />

          {vehicle?.antt_owner_id && (
            <AlertModal
              size="sm"
              titleButton="Desvincular ANTT"
              title="Desvincular ANTT"
              description={`Tem certeza que deseja desvincular ${anttOwner?.name}?`}
              colorScheme="red"
              disabled={anttOwner?.id !== vehicle?.antt_owner_id}
              isLoading={updateVehicle.isLoading}
              leftIcon={<RiUser2Fill />}
              onConfirm={unlinkOwnerFromVehicle}
            />
          )}
        </Flex>

        {blockedEntity && (
          <Alert variant="subtle" status="error" borderRadius="md">
            <AlertIcon />
            <AlertTitle mr={2} fontSize="sm">
              Edição bloqueada. Solicite correção da análise de risco
            </AlertTitle>
          </Alert>
        )}
        <FormProvider {...methods}>
          <AnttForm
            disabled={blockedEntity}
            hasOwner={!!vehicle?.antt_owner_id}
            vehicleLicensePlate={vehicle?.license_plate}
            onOwnerAlreadyFound={data => {
              if (data) {
                crateInitialValues(data)
                setAnttOwner(data)
                return
              }
              setAnttOwner(null)
            }}
          />
        </FormProvider>
      </DrawerWrapper>
    </>
  )
}

export default AnttModalForm
