import {
  addDays,
  addMonths,
  differenceInDays,
  isThursday,
  isTuesday,
  nextThursday,
  nextTuesday,
  setDate,
} from 'date-fns'
import getOnlyNumbers from './getOnlyNumbers'

const companiesParameters = [
  {
    taker: 'ADASEBO',
    taker_alias: 'ADASEBO',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'ADELUZ SOLUCOES CONSTRUTIVAS LTDA',
    taker_alias: 'ADELUZ',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'AGIL - COMERCIO E DISTRIBUICAO DE ALIMENTOS LTDA',
    taker_alias: 'AGIL',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'AHLSTROM-MUNKSJO BRASIL INDUSTRIA E',
    taker_alias: 'AHLSTROM-MUNKSJO',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'ALIMAQ MIX MAQUINAS E FERRAMENTAS L',
    taker_alias: 'ALIMAQ (SUZANO)',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'ALIMAQ MIX MAQUINAS E FERRAMENTAS L',
    taker_alias: 'ALIMAQ (SUZANO)',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'AMATEL MADEIRAS E TELHAS LTDA',
    taker_alias: 'AMATEL',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'ARAUCO CELULOSE DO BRASIL S.A.',
    taker_alias: 'ARAUCO',
    ddr: 'SIM',
    pocket_insurance: 0.0006,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0004,
  },
  {
    taker: 'ARAUCO DO BRASIL S.A.',
    taker_alias: 'ARAUCO',
    ddr: 'SIM',
    pocket_insurance: 0.0006,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0004,
  },
  {
    taker: 'ARAUCO DO BRASIL S.A.',
    taker_alias: 'ARAUCO',
    ddr: 'SIM',
    pocket_insurance: 0.0006,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0004,
  },
  {
    taker: 'ARAUCO DO BRASIL S.A. - UNIDADE CURITIBA/PR',
    taker_alias: 'ARAUCO',
    ddr: 'SIM',
    pocket_insurance: 0.0006,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0004,
  },
  {
    taker: 'ARAUCO DO BRASIL S.A. - UNIDADE JAGUARIAIVA/PR',
    taker_alias: 'ARAUCO',
    ddr: 'SIM',
    pocket_insurance: 0.0006,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0004,
  },
  {
    taker: 'ARAUCO DO BRASIL S.A. - UNIDADE PIEN/PR',
    taker_alias: 'ARAUCO',
    ddr: 'SIM',
    pocket_insurance: 0.0006,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0004,
  },
  {
    taker: 'ARAUCO FLORESTAL ARAPOTI S/A',
    taker_alias: 'ARAUCO',
    ddr: 'SIM',
    pocket_insurance: 0.0006,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0004,
  },
  {
    taker: 'ARAUCO INDUSTRIA DE PAINEIS S.A.',
    taker_alias: 'ARAUCO',
    ddr: 'SIM',
    pocket_insurance: 0.0006,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0004,
  },
  {
    taker: 'ARAUCO INDUSTRIA DE PAINEIS S.A. - UNIDADE PONTA GROSSA/PR',
    taker_alias: 'ARAUCO',
    ddr: 'SIM',
    pocket_insurance: 0.0006,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0004,
  },
  {
    taker: 'GENERAL ROLLER EQUIPAMENTOS INDUSTRIAIS LTDA',
    taker_alias: 'ARAUCO',
    ddr: 'SIM',
    pocket_insurance: 0.0006,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0004,
  },
  {
    taker: 'BEPLAST OND E COM DE PLASTICOS LTDA',
    taker_alias: 'BEPLAST',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'BRACELL SP CELULOSE LTDA',
    taker_alias: 'BRACELL',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'BRACELL SP CELULOSE LTDA .',
    taker_alias: 'BRACELL',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'BRACELL SP CELULOSE LTDA 00054314',
    taker_alias: 'BRACELL',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'BRACELL SP FLORESTAL LTDA',
    taker_alias: 'BRACELL',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'MSFC FLORESTAL',
    taker_alias: 'BRACELL',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'MSFC FLORESTAL LTDA',
    taker_alias: 'BRACELL',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'BRAMETAL S/A',
    taker_alias: 'BRAMETAL',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'BRAMETAL SUL',
    taker_alias: 'BRAMETAL',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'BRAMETAL SUL METALURGICA LTDA',
    taker_alias: 'BRAMETAL',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'CAIEIRAS INDUSTRIA E COMERCIO DE PAPEIS ESPECIAIS LTDA.',
    taker_alias: 'CAIEIRAS',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'CARDEAL INDUSTRIA DE PLASTICOS E SERVICOS LTDA',
    taker_alias: 'CARDEAL',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'BPG AV MOFARREJ EMPREENDIMENTOS E PARTICIPACOES S.A',
    taker_alias: 'CASSOL',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'CASSOL PRE FABRICADOS LTDA - ARAUCARIA/PR',
    taker_alias: 'CASSOL',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'CASSOL PRE FABRICADOS LTDA - MONTE-MOR/SP',
    taker_alias: 'CASSOL',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'CASSOL PRE FABRICADOS LTDA - SEROPEDICA/RJ',
    taker_alias: 'CASSOL',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'CONCESSIONARIA ALLEGRA PACAEMBU SPE S/A',
    taker_alias: 'CASSOL',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'EMPRESA LITORANEA DE TRANSMISSAO DE ENERGIA S.A.  ELTE',
    taker_alias: 'CASSOL',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'HERSA ENGENHARIA LTDA',
    taker_alias: 'CASSOL',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'PERFECT WAVE POOL S/A',
    taker_alias: 'CASSOL',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'CIA DE FERRO E LIGAS BAHIA-FERBASA - ANDORINHA/BA',
    taker_alias: 'CIA DE FERRO',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'CIA DE FERRO E LIGAS BAHIA-FERBASA - POJUCA/BA',
    taker_alias: 'CIA DE FERRO',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'CMPC CELULOSE RIOGRANDENSE LTDA',
    taker_alias: 'CMPC',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'COLORADO COMERCIO DE MAQUINAS AGRICOLAS LTDA.',
    taker_alias: 'COLORADO MAQ. AGRIC.',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'CONTREX ENGENHARIA E SERVICOS LTDA',
    taker_alias: 'CONTREX (SUZANO)',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'D F A',
    taker_alias: 'D F A',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'DISTRIBUIDORA DE PRODUTOS ALIMENTICIOS DISBOM LTDA',
    taker_alias: 'DISBOM DISTRIBUIDORA',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'DISNORTE ATACADISTA E DISTRIBUIDORA DE ALIMENTOS LTDA',
    taker_alias: 'DISNORTE',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'ENERGISA MATO GROSSO - DISTRIBUIDORA DE ENERGIA S.A.',
    taker_alias: 'ENERGISA',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'ENERGISA MATO GROSSO DO SUL - DISTRIBUIDORA DE ENERGIA S.A.',
    taker_alias: 'ENERGISA',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'ENERGISA MINAS RIO DISTRIB.ENERGIA SA',
    taker_alias: 'ENERGISA',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'ENERGISA SERGIPE DISTRIBUIDORA DE ENERGIA S A',
    taker_alias: 'ENERGISA',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'ENERGISA SERGIPE DISTRIBUIDORA DE ENERGIA S A',
    taker_alias: 'ENERGISA',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'ENERGISA TOCANTINS',
    taker_alias: 'ENERGISA',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'ENERGISA TOCANTINS',
    taker_alias: 'ENERGISA',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'MARIA INEZ CASER FRANCA',
    taker_alias: 'ENERGISA',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'ERO BRASIL CARAIBA',
    taker_alias: 'ERO BRASIL',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'ERO BRASIL PARTICIPACOES LTDA.',
    taker_alias: 'ERO BRASIL',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'MINERACAO CARAIBA S/A',
    taker_alias: 'ERO BRASIL',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'ETERNIT S.A EM RECUPERACAO JUDICIAL - ATIBAIA/SP',
    taker_alias: 'ETERNIT',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'FLORAPLAC MDF LTDA',
    taker_alias: 'FLORAPAC',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'FRISA NORDESTE',
    taker_alias: 'FRISA',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'GIGLIO S/A INDUSTRIA E COMERCIO',
    taker_alias: 'GIGLIO',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'DISTRIBUIDORA DE MADEIRAS PARANAENSE LTDA',
    taker_alias: 'GREENPLAC',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'GREENPLAC TECNOLOGIA INDUSTRIAL LTDA',
    taker_alias: 'GREENPLAC',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'HIDRAULICA INDUSTRIAL IND. E COM. L',
    taker_alias: 'HIDRAULICA IND',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'IMETAME ENERGIA LTDA',
    taker_alias: 'IMETAME',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'IMETAME METALMECANICA LTDA',
    taker_alias: 'IMETAME',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'INCOPOSTES - CE',
    taker_alias: 'INCOPOSTES',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'INCOPOSTES INDUSTRIA E COMERCIO DE POSTES LTDA',
    taker_alias: 'INCOPOSTES',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'INUSA ENGENHARIA E TECNOLOGIA EM ESTRUTURAS METALICAS LTDA',
    taker_alias: 'INUSA',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'ISOMETAL ISOLAMENTO TERMICO E INDUSTRIA METALURGICA LTDA',
    taker_alias: 'ISOMETAL',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'JACUZZI DO BRASIL IND.COM.LTDA',
    taker_alias: 'JACUZZI',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'ESTALEIRO JURONG ARACRUZ LTDA',
    taker_alias: 'JURONG',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'KLABIN DA AMAZONIA SOLUCOES EM EMBALAGENS DE PAPEL LTDA.',
    taker_alias: 'KLABIN',
    ddr: 'SIM',
    pocket_insurance: 0.0006,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0004,
  },
  {
    taker: 'KLABIN S.A.',
    taker_alias: 'KLABIN',
    ddr: 'SIM',
    pocket_insurance: 0.0006,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0004,
  },
  {
    taker: 'KLABIN S.A. - UNIDADE ANGATUBA/SP',
    taker_alias: 'KLABIN',
    ddr: 'SIM',
    pocket_insurance: 0.0006,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0004,
  },
  {
    taker: 'KLABIN S.A. - UNIDADE BETIM/MG',
    taker_alias: 'KLABIN',
    ddr: 'SIM',
    pocket_insurance: 0.0006,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0004,
  },
  {
    taker: 'KLABIN S.A. - UNIDADE CORREIA PINTO/SC',
    taker_alias: 'KLABIN',
    ddr: 'SIM',
    pocket_insurance: 0.0006,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0004,
  },
  {
    taker: 'KLABIN S.A. - UNIDADE FEIRA DE SANTANA/BA',
    taker_alias: 'KLABIN',
    ddr: 'SIM',
    pocket_insurance: 0.0006,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0004,
  },
  {
    taker: 'KLABIN S.A. - UNIDADE FRANCO DA ROCHA/SP',
    taker_alias: 'KLABIN',
    ddr: 'SIM',
    pocket_insurance: 0.0006,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0004,
  },
  {
    taker: 'KLABIN S.A. - UNIDADE GOIANA/PE',
    taker_alias: 'KLABIN',
    ddr: 'SIM',
    pocket_insurance: 0.0006,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0004,
  },
  {
    taker: 'KLABIN S.A. - UNIDADE JUNDIAI/SP (JD GUANABARA)',
    taker_alias: 'KLABIN',
    ddr: 'SIM',
    pocket_insurance: 0.0006,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0004,
  },
  {
    taker: 'KLABIN S.A. - UNIDADE ORTIGUEIRA/PR',
    taker_alias: 'KLABIN',
    ddr: 'SIM',
    pocket_insurance: 0.0006,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0004,
  },
  {
    taker: 'KLABIN S.A. - UNIDADE OTACILIO COSTA/SC',
    taker_alias: 'KLABIN',
    ddr: 'SIM',
    pocket_insurance: 0.0006,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0004,
  },
  {
    taker: 'KLABIN S.A. - UNIDADE PAULINIA/SP',
    taker_alias: 'KLABIN',
    ddr: 'SIM',
    pocket_insurance: 0.0006,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0004,
  },
  {
    taker: 'KLABIN S.A. - UNIDADE PIRACICABA/SP',
    taker_alias: 'KLABIN',
    ddr: 'SIM',
    pocket_insurance: 0.0006,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0004,
  },
  {
    taker: 'KLABIN S.A. - UNIDADE RIO VERDE/GO',
    taker_alias: 'KLABIN',
    ddr: 'SIM',
    pocket_insurance: 0.0006,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0004,
  },
  {
    taker: 'KLABIN S.A. - UNIDADE SAO PAULO/SP',
    taker_alias: 'KLABIN',
    ddr: 'SIM',
    pocket_insurance: 0.0006,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0004,
  },
  {
    taker: 'KLABIN S.A. - UNIDADE SUZANO/SP',
    taker_alias: 'KLABIN',
    ddr: 'SIM',
    pocket_insurance: 0.0006,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0004,
  },
  {
    taker: 'KLABIN S.A. - UNIDADE TELEMACO BORBA/PR',
    taker_alias: 'KLABIN',
    ddr: 'SIM',
    pocket_insurance: 0.0006,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0004,
  },
  {
    taker: 'KLABIN S.A. GOIANA',
    taker_alias: 'KLABIN',
    ddr: 'SIM',
    pocket_insurance: 0.0006,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0004,
  },
  {
    taker: 'HOSPITAL E MATERNIDADE SAO MATEUS LTDA',
    taker_alias: 'KORA SAUDE',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'KORBER BRASIL LTDA',
    taker_alias: 'KORBER BRASIL',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'LIPETRAL LINHARES PECAS E TRATORES LTDA',
    taker_alias: 'LIPETRAL',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 45,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'LIPETRAL LINHARES PECAS E TRATORES LTDA  - 027733195',
    taker_alias: 'LIPETRAL',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 45,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'LOGICA DISTRIBUICAO E COMERCIO DE PRODUTOS ALIMENTICIOS LTDA',
    taker_alias: 'LOGICA ALIMENTOS',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 15,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'T V V - TERMINAL DE VILA VELHA S.A',
    taker_alias: 'LOGIN/TVV',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'MAHAL EMPREENDIMENTOS E PARTICIPAÇÕES S.A',
    taker_alias: 'MAHAL EMPREENDIMENTOS',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'IDE - INSTITUTO DE DIAG. ESPECIALIZADOS LTDA.',
    taker_alias: 'MERIDIONAL',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'METSO BRAZIL INDUSTRIA E COMERCIO LTDA',
    taker_alias: 'METSO',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'METSO BRAZIL INDUSTRIA E COMERCIO LTDA.',
    taker_alias: 'METSO',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'MINERACAO CURIMBABA LTDA',
    taker_alias: 'MINERAÇÃO CURIMBABA',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'OLEPLASTIC EMBALAGENS DESCARTAVEIS LTDA',
    taker_alias: 'OLEPLASTIC',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'P & L AGROINDUSTRIA DE LATICINIOS LTDA',
    taker_alias: 'P& L AGROINDUSTRIA',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'PLACAS DO BRASIL S/A',
    taker_alias: 'PLACAS DO BRASIL',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'PONTO NORTE COMERCIO TRANSPORTE E LOGISTICA LTDA',
    taker_alias: 'PONTO NORTE',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'BIOENERGIA MARACAI LTDA',
    taker_alias: 'RAÍZEN',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'BIOSEV BIOENERGIA S.A.',
    taker_alias: 'RAÍZEN',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'BIOSEV S.A.',
    taker_alias: 'RAÍZEN',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'BIOSEV S.A. LAGOA DA PRATA',
    taker_alias: 'RAÍZEN',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'BIOSEV S.A. RIO BRILHANTE',
    taker_alias: 'RAÍZEN',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'RAIZEN CAARAPO ACUCAR E ALCOOL LTDA',
    taker_alias: 'RAÍZEN',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'RAIZEN CAARAPO ACUCAR E ALCOOL LTDA - MARACAI',
    taker_alias: 'RAÍZEN',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'RAIZEN CENTRO-SUL PAULISTA S.A',
    taker_alias: 'RAÍZEN',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'RAIZEN CENTRO-SUL PAULISTA S.A.',
    taker_alias: 'RAÍZEN',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'RAIZEN CENTRO-SUL S.A',
    taker_alias: 'RAÍZEN',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'RAIZEN ENERGIA S.A',
    taker_alias: 'RAÍZEN',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'RAIZEN ENERGIA S.A.',
    taker_alias: 'RAÍZEN',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'RAIZEN ENERGIA S/A',
    taker_alias: 'RAÍZEN',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'RAIZEN ENERGIA SA',
    taker_alias: 'RAÍZEN',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'RAIZEN S.A.',
    taker_alias: 'RAÍZEN',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'ROMAPACK IMP EXP E IND DE EMBALAGENS LTDA - ITAJAI/SC',
    taker_alias: 'ROMAPACK',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'RT DISTRIBUIDORA DE ALIMENTOS EIRELI',
    taker_alias: 'RT DISTRIBUIDORA',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'SANDENE S.A. INDUSTRIA E COMERCIO',
    taker_alias: 'SANDENE',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'SANREMO S/A',
    taker_alias: 'SANREMO',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'SEAL DISTRIBUIDORA IMPORTACAO E EXPORTACAO LTDA',
    taker_alias: 'SEAL DISTRIBUIDORA',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'SFBC PARTICIPACOES LTDA',
    taker_alias: 'SFB PARTICIPAÇÕES',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'SKYSTONE DO BRASIL LTDA',
    taker_alias: 'SKYSTONE',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'SMURFIT KAPPA DO BRASIL INDUSTRIA DE EMBALAGENS S.A.',
    taker_alias: 'SMURFIT',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'SMURFIT KAPPA DO BRASIL INDUSTRIA DE EMBALAGENS SA',
    taker_alias: 'SMURFIT',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'SOBRE RODAS COMERCIO E EQUIPAMENTO LTDA',
    taker_alias: 'SOBRE RODAS (SUZANO)',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'ACQUA AGUA DE COCO DA AMAZ IND COM LTDA',
    taker_alias: 'SOCOCO',
    ddr: 'SIM',
    pocket_insurance: 0.0006,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0004,
  },
  {
    taker: 'SOCOCO S/A INDUSTRIAS ALIMENTICIAS',
    taker_alias: 'SOCOCO',
    ddr: 'SIM',
    pocket_insurance: 0.0006,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0004,
  },
  {
    taker: 'SOMMA DISTRIBUIDORA',
    taker_alias: 'SOMMA',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'SOMMA COMERCIO E ATACADISTA DE ALIMENTOS LTDA',
    taker_alias: 'SOMMA COMERCIO',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'SONOCO DO BRASIL LTDA',
    taker_alias: 'SONOCO',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'SONOCO TUBOS E CONES LTDA',
    taker_alias: 'SONOCO',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'SOPETRA ROLAMENTOS E PECAS LTDA - MUCURI/BA',
    taker_alias: 'SOPETRA',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'SPOTX LOGISTICA LTDA',
    taker_alias: 'SPOTX',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'SPOTX LOGISTICA LTDA - MATRIZ',
    taker_alias: 'SPOTX',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'SPOTX LOGISTICA LTDA - SAO LUIS/MA',
    taker_alias: 'SPOTX',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'SPOTX LOGÍSTICA LTDA - SAO PAULO/SP',
    taker_alias: 'SPOTX',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'S.T FLEX INDUSTRIA E COM DE MANGUEIRAS EIRELI',
    taker_alias: 'ST FLEX (JURONG)',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'MACRO SOLUCOES LTDA',
    taker_alias: 'SUZANO',
    ddr: 'SIM',
    pocket_insurance: 0.0004,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0,
  },
  {
    taker: 'MACRO SOLUCOES LTDA',
    taker_alias: 'SUZANO',
    ddr: 'SIM',
    pocket_insurance: 0.0004,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0,
  },
  {
    taker: 'NOVA LIMP COMERCIO DE EMBALAGENS',
    taker_alias: 'SUZANO',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'PORTOCEL TERMINAL ESPECIALIZADO DE BARRA DO RIACHO S/A',
    taker_alias: 'SUZANO',
    ddr: 'SIM',
    pocket_insurance: 0.0004,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0,
  },
  {
    taker: 'SUZANO S.A',
    taker_alias: 'SUZANO',
    ddr: 'SIM',
    pocket_insurance: 0.0004,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0,
  },
  {
    taker: 'SUZANO S.A.',
    taker_alias: 'SUZANO',
    ddr: 'SIM',
    pocket_insurance: 0.0004,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0,
  },
  {
    taker: 'SUZANO S.A. - UNID SUZANO',
    taker_alias: 'SUZANO',
    ddr: 'SIM',
    pocket_insurance: 0.0004,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0,
  },
  {
    taker: 'SUZANO S.A. - UNID SUZANO',
    taker_alias: 'SUZANO',
    ddr: 'SIM',
    pocket_insurance: 0.0004,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0,
  },
  {
    taker: 'SUZANO S.A. (FUTURAGENE)',
    taker_alias: 'SUZANO',
    ddr: 'SIM',
    pocket_insurance: 0.0004,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0,
  },
  {
    taker: 'SUZANO S.A. UNIDADE ACAILANDIA/MA',
    taker_alias: 'SUZANO',
    ddr: 'SIM',
    pocket_insurance: 0.0004,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0,
  },
  {
    taker: 'SUZANO S.A. UNIDADE ARACRUZ/ES',
    taker_alias: 'SUZANO',
    ddr: 'SIM',
    pocket_insurance: 0.0004,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0,
  },
  {
    taker: 'SUZANO S.A. UNIDADE BELEM/PA',
    taker_alias: 'SUZANO',
    ddr: 'SIM',
    pocket_insurance: 0.0004,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0,
  },
  {
    taker: 'SUZANO S.A. UNIDADE CACHOEIRO DE ITAPEMIRIM/ES',
    taker_alias: 'SUZANO',
    ddr: 'SIM',
    pocket_insurance: 0.0004,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0,
  },
  {
    taker: 'SUZANO S.A. UNIDADE CIDELANDIA/MA',
    taker_alias: 'SUZANO',
    ddr: 'SIM',
    pocket_insurance: 0.0004,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0,
  },
  {
    taker: 'SUZANO S.A. UNIDADE FLORESTAL TRES LAGOAS/MS',
    taker_alias: 'SUZANO',
    ddr: 'SIM',
    pocket_insurance: 0.0004,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0,
  },
  {
    taker: 'SUZANO S.A. UNIDADE IMPERATRIZ/MA',
    taker_alias: 'SUZANO',
    ddr: 'SIM',
    pocket_insurance: 0.0004,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0,
  },
  {
    taker: 'SUZANO S.A. UNIDADE JACAREI/SP',
    taker_alias: 'SUZANO',
    ddr: 'SIM',
    pocket_insurance: 0.0004,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0,
  },
  {
    taker: 'SUZANO S.A. UNIDADE LIMEIRA/SP',
    taker_alias: 'SUZANO',
    ddr: 'SIM',
    pocket_insurance: 0.0004,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0,
  },
  {
    taker: 'SUZANO S.A. UNIDADE MARACANAU/CE I',
    taker_alias: 'SUZANO',
    ddr: 'SIM',
    pocket_insurance: 0.0004,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0,
  },
  {
    taker: 'SUZANO S.A. UNIDADE MARACANAU/CE II',
    taker_alias: 'SUZANO',
    ddr: 'SIM',
    pocket_insurance: 0.0004,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0,
  },
  {
    taker: 'SUZANO S.A. UNIDADE MUCURI/BA',
    taker_alias: 'SUZANO',
    ddr: 'SIM',
    pocket_insurance: 0.0004,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0,
  },
  {
    taker: 'SUZANO S.A. UNIDADE SUZANO/SP',
    taker_alias: 'SUZANO',
    ddr: 'SIM',
    pocket_insurance: 0.0004,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0,
  },
  {
    taker: 'SUZANO S.A. UNIDADE TEIXEIRA DE FREITAS/BA',
    taker_alias: 'SUZANO',
    ddr: 'SIM',
    pocket_insurance: 0.0004,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0,
  },
  {
    taker: 'SUZANO S.A. UNIDADE TRES LAGOAS/MS I',
    taker_alias: 'SUZANO',
    ddr: 'SIM',
    pocket_insurance: 0.0004,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0,
  },
  {
    taker: 'SUZANO S.A. UNIDADE VIANA/ES',
    taker_alias: 'SUZANO',
    ddr: 'SIM',
    pocket_insurance: 0.0004,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0,
  },
  {
    taker: 'TECLIT SERVICOS LTDA',
    taker_alias: 'TECLIT',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'TERRAVERDE MAQUINAS AGRICOLAS LTDA',
    taker_alias: 'TERRA VERDE (LIPETRAL)',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 45,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'TROMBINI EMBALAGENS S/A',
    taker_alias: 'TROMBINI EMBALAGENS',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'VIX LOGISTICA S/A',
    taker_alias: 'VIX LOGISTICA',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'WEG CESTARI RED. MOTORRED. S/A',
    taker_alias: 'WEG',
    ddr: 'SIM',
    pocket_insurance: 0.0006,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0004,
  },
  {
    taker: 'WEG DRIVES & CONTROLS - AUTOMACAO LTDA - JARAGUA DO SUL/SC',
    taker_alias: 'WEG',
    ddr: 'SIM',
    pocket_insurance: 0.0006,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0004,
  },
  {
    taker: 'WEG DRIVES & CONTROLS - AUTOMACAO LTDA - RIBEIRAO PRETO/SP',
    taker_alias: 'WEG',
    ddr: 'SIM',
    pocket_insurance: 0.0006,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0004,
  },
  {
    taker: 'WEG DRIVES & CONTROLS - AUTOMACAO LTDA - SAO BERNARDO DO CAMPO/SP',
    taker_alias: 'WEG',
    ddr: 'SIM',
    pocket_insurance: 0.0006,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0004,
  },
  {
    taker: 'WEG EQUIP ELETRICOS S.A. - BETIM/MG',
    taker_alias: 'WEG',
    ddr: 'SIM',
    pocket_insurance: 0.0006,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0004,
  },
  {
    taker: 'WEG EQUIP ELETRICOS S.A. - JARAGUA DO SUL/SC (NOVA BRASILIA)',
    taker_alias: 'WEG',
    ddr: 'SIM',
    pocket_insurance: 0.0006,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0004,
  },
  {
    taker: 'WEG EQUIP ELETRICOS S.A. - JARAGUA DO SUL/SC (VILA LALAU I)',
    taker_alias: 'WEG',
    ddr: 'SIM',
    pocket_insurance: 0.0006,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0004,
  },
  {
    taker: 'WEG EQUIP ELETRICOS S.A. - SAO BERNARDO DO CAMPO/SP',
    taker_alias: 'WEG',
    ddr: 'SIM',
    pocket_insurance: 0.0006,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0004,
  },
  {
    taker: 'WEG EQUIP ELETRICOS S.A. - SERTAOZINHO/SP',
    taker_alias: 'WEG',
    ddr: 'SIM',
    pocket_insurance: 0.0006,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0004,
  },
  {
    taker: 'WEG EQUIPAMENTOS ELETRICOS S/A',
    taker_alias: 'WEG',
    ddr: 'SIM',
    pocket_insurance: 0.0006,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0004,
  },
  {
    taker: 'WEG EQUIPAMENTOS ELETRICOS S/A 1001 - WEN - SAO VICENTE - RN',
    taker_alias: 'WEG',
    ddr: 'SIM',
    pocket_insurance: 0.0006,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0004,
  },
  {
    taker: 'WEG LINHARES EQUIPAMENTOS ELETRICOS S.A.',
    taker_alias: 'WEG',
    ddr: 'SIM',
    pocket_insurance: 0.0006,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0004,
  },
  {
    taker: 'WEG TURBINAS E SOLAR LTDA.',
    taker_alias: 'WEG',
    ddr: 'SIM',
    pocket_insurance: 0.0006,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0004,
  },
  {
    taker: 'WEG TURBINAS LTDA.',
    taker_alias: 'WEG',
    ddr: 'SIM',
    pocket_insurance: 0.0006,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0004,
  },
  {
    taker: 'RUMO MALHA PAULISTA S.A',
    taker_alias: 'RUMO',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'GRANDIS AGROFLORESTAL S/A',
    taker_alias: 'GRANDIS',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'ALPARGATAS S/A',
    taker_alias: 'ALPARGATAS',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'RUMO MALHA SUL S.A',
    taker_alias: 'RUMO',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'ELDORADO BRASIL CELULOSE S/A',
    taker_alias: 'ELDORADO',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'COPAPA CIA PADUANA DE PAPEIS',
    taker_alias: 'COPAPA',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 7,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'GENICA INOVACAO BIOTECNOLOGICA S/A EP-81986-7',
    taker_alias: 'GENICA',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'EMAN SERVICOS INDUSTRIAIS LTDA',
    taker_alias: 'EMAN',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'ALPARGATAS S.A.',
    taker_alias: 'ALPARGATAS',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'AUTO DEFESA BRASIL TECNOLOGIA E MONITORAMENTO S/A',
    taker_alias: 'AUTO DEFESA',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 15,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'MSFC FLORESTAL LTDA (FAZ. MACHADO)',
    taker_alias: 'BRACELL',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'MSFC FLORESTAL LTDA MSFC ESCRITÓRIO ADM. ÁGUA BRANCA',
    taker_alias: 'BRACELL',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'MSFC FLORESTAL LTDA MSFC ESCRITÓRIO ADM. TRÊS LAGOAS',
    taker_alias: 'BRACELL',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'HECTAR AGRONEGOCIOS LTDA',
    taker_alias: 'HECTAR AGRO',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 15,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'JBS SA',
    taker_alias: 'JBS',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'O.K.S SERVICOS E CONSTRUCOES EIRELI',
    taker_alias: 'OKS',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 7,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'OLIVEIRA IND. E COM. DE RETENTORES LTDA',
    taker_alias: 'OLIVEIRA RETENTORES',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 15,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'ALL - AMERICA LATINA LOGISTICA MALHA OESTE S.A',
    taker_alias: 'RUMO',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'ALL - AMERICA LATINA LOGISTICA MALHA SUL S.A.',
    taker_alias: 'RUMO',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'ASSOCIACAO GESTORA FIPS',
    taker_alias: 'RUMO',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'RUMO MALHA CENTRAL S.A - FILIAL PALMAS',
    taker_alias: 'RUMO',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'RUMO MALHA NORTE S.A',
    taker_alias: 'RUMO',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'PORTOCEL TERMINAL ESPECIALIZADO BARRA RIACHO S/A',
    taker_alias: 'SUZANO',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'VALE S.A.',
    taker_alias: 'VALE',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'VILA ERVAS VILA ALIMENTOS LTDA',
    taker_alias: 'VILA ERVAS',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 7,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'WEG EQUIP ELETRICOS S.A. - JARAGUA DO SUL/SC (VILA LALAU II)',
    taker_alias: 'WEG',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'WESTROCK, CELULOSE, PAPEL E EMBALAGENS LTDA',
    taker_alias: 'WESTROCK',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'WESTROCK CELULOSE PAPEL E EMBALAGENS LTDA',
    taker_alias: 'WESTROCK',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'DIAMOND POWER DO BRASIL LIMITADA',
    taker_alias: 'DIAMOND',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 7,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'TERMINAL SAO SIMAO S.A',
    taker_alias: 'RUMO',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'BRACELL SP FLORESTAL LTDA.',
    taker_alias: 'BRACELL',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'ABECOM ROLAMENTOS E PRODUTOS DE BORRACHA LTDA',
    taker_alias: 'ABECOM',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 7,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'ELDORADO BRASIL CELULOSE SA',
    taker_alias: 'ELDORADO',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'YORGOS AMBIENTAL LTDA',
    taker_alias: 'YORGOS',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 15,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
  {
    taker: 'VALGROUP AM INDUSTRIA DE MASTERBATCH LTDA',
    taker_alias: 'VALGROUP',
    ddr: 'NÃO',
    pocket_insurance: 0.0008,
    payment_deadline: 30,
    client_type: 'ANTIGO',
    effective_insurance: 0.0008,
  },
]

export function getTakerAlias(taker: string): string | undefined {
  return companiesParameters.find(i => i.taker === taker)?.taker_alias
}

export function getPocketInsurancePercent(taker: string): number | undefined {
  const findTaker = companiesParameters.find(i => i.taker === taker)
  if (findTaker) {
    return findTaker.pocket_insurance
  }
  return undefined
}

export function getPocketInsuranceValue(origin: string, destination: string, cargoValue: number): number {
  const originIsRJ = origin.includes('- RJ')
  const destinationIsRJ = destination.includes('- RJ')

  let additionByRoute = 0
  if (originIsRJ || destinationIsRJ) {
    additionByRoute = cargoValue * 0.0011
  }

  let additionByCargoValue = 0
  if (cargoValue > 200000) {
    additionByCargoValue = cargoValue * 0.001
  }

  const result = additionByRoute + additionByCargoValue
  return -result
}

export function getFinancialExpense(
  freightValue: number,
  emit_date: string | Date,
  payment_date?: string | Date | null,
): number {
  if (!payment_date) return 0
  const diffInDays = differenceInDays(new Date(emit_date), new Date(payment_date))
  const percentage = diffInDays * 0.001
  return freightValue * percentage
}

export function getClientPaymentDate(taker_cnpj: string, emit_date?: Date): Date | null {
  let cnpj = taker_cnpj
  if (!cnpj || !emit_date) return null
  cnpj = getOnlyNumbers(cnpj)
  const emitDate = new Date(emit_date)
  // AGIL - COMERCIO E DISTRIBUICAO DE ALIMENTOS LTDA
  if (cnpj.startsWith('16638936')) {
    return addDays(emitDate, 30)
  }
  // AHLSTROM-MUNKSJO BRASIL INDUSTRIA E COMERCIO DE PAPEIS ESPEC
  if (cnpj.startsWith('00767144')) {
    return addDays(emitDate, 15)
  }
  // ARAUCO do Brasil
  if (
    cnpj.startsWith('76518836') ||
    cnpj.startsWith('07609453') ||
    cnpj.startsWith('00606549') ||
    cnpj.startsWith('10796374') ||
    cnpj.startsWith('47658073')
  ) {
    return addDays(emitDate, 30)
  }
  // BRACEL
  if (cnpj.startsWith('53943098')) {
    return addDays(emitDate, 30)
  }
  // BRAMETAL S/A
  if (cnpj.startsWith('83249078')) {
    return addDays(emitDate, 30)
  }
  // CAIEIRAS INDUSTRIA E COMERCIO DE PAPEIS ESPECIAIS LTDA.
  if (cnpj.startsWith('30910705')) {
    const date = addDays(emitDate, 30)
    if (date.getDate() === 5 || date.getDate() === 20) {
      return date
    }
    if (date.getDate() < 5) {
      return setDate(date, 5)
    }
    if (date.getDate() > 5 && date.getDate() < 20) {
      return setDate(date, 20)
    }
    if (date.getDate() > 20) {
      return setDate(addMonths(date, 1), 5)
    }
  }
  // CASSOL PRE-FABRICADOS LTDA
  if (cnpj.startsWith('86183449')) {
    return addDays(emitDate, 30)
  }
  // COLORADO MAQ. AGRIC.
  if (cnpj.startsWith('03258870')) {
    return addDays(emitDate, 15)
  }
  // COPAPA CIA PADUANA DE PAPEIS
  if (cnpj.startsWith('31590862')) {
    return addDays(emitDate, 30)
  }
  if (cnpj.startsWith('20200104')) {
    return addDays(emitDate, 14)
  }
  if (cnpj.startsWith('11934871')) {
    return addDays(emitDate, 7)
  }
  if (cnpj.startsWith('45989050')) {
    const date = addDays(emitDate, 30)
    if (date.getDate() === 15) {
      return date
    }
  }
  // Cervejaria Petropolis Da Bahia Ltda Em Recuperacao Judicial
  if (cnpj.startsWith('15350602')) {
    return addDays(emitDate, 15)
  }
  // Cervejaria Petropolis De Pernambuco Ltda Em Recuperacao Judicial
  if (cnpj.startsWith('16622166')) {
    return addDays(emitDate, 15)
  }
  // DISNORTE ATACADISTA E DISTRIBUIDORA DE ALIMENTOS LTDA
  if (cnpj.startsWith('30547651')) {
    return addDays(emitDate, 15)
  }
  // Ero Brasil Participacoes Ltda.
  if (cnpj.startsWith('41586670')) {
    return addDays(emitDate, 45)
  }
  if (cnpj.startsWith('32423187')) {
    return addDays(emitDate, 15)
  }
  // ESTALEIRO JURONG
  if (cnpj.startsWith('11200595')) {
    return addDays(emitDate, 30)
  }
  // GIGLIO S A INDUSTRIA E COMERCIO
  if (cnpj.startsWith('59105635')) {
    return addDays(emitDate, 20)
  }
  // Teclit Servicos Ltda
  if (cnpj.startsWith('39375449')) {
    return addDays(emitDate, 15)
  }
  // GREENPLAC TECNOLOGIA INDUSTRIAL LTDA
  if (cnpj.startsWith('03801905')) {
    const date = addDays(emitDate, 30)
    if (isThursday(date)) {
      return date
    }
    const dateThursday = nextThursday(date)
    return dateThursday
  }
  // IMETAME ENERGIA LTDA.
  if (cnpj.startsWith('00271847' || cnpj.startsWith('31790710'))) {
    const date = addDays(emitDate, 30)
    if (isThursday(date)) {
      return date
    }
    return nextThursday(date)
  }
  // INUSA
  if (cnpj.startsWith('13260199')) {
    return addDays(emitDate, 30)
  }
  // INCOPOSTES INDUSTRIA E COMERCIO DE POSTES LTDA
  if (cnpj.startsWith('05433048')) {
    return addDays(emitDate, 15)
  }
  // KLABIN
  if (cnpj.startsWith('89637490')) {
    const date = addDays(emitDate, 30)
    if (date.getDate() === 5 || date.getDate() === 20) {
      return date
    }
    if (date.getDate() < 5) {
      return setDate(date, 5)
    }
    if (date.getDate() > 5 && date.getDate() < 20) {
      return setDate(date, 20)
    }
    if (date.getDate() > 20) {
      return setDate(addMonths(date, 1), 5)
    }
  }
  // KORA SAUDE
  if (cnpj.startsWith('15016827')) {
    return addDays(emitDate, 20)
  }
  // KORBER BRASIL
  if (cnpj.startsWith('46861449')) {
    return addDays(emitDate, 14)
  }
  // Rondinha Energetica S.a.
  if (cnpj.startsWith('10759764')) {
    const date = addDays(emitDate, 15)
    if (date.getDate() === 10 || date.getDate() === 15 || date.getDate() === 20) {
      return date
    }
  }
  // LIPETRAL
  if (cnpj.startsWith('27733195')) {
    return addDays(emitDate, 45)
  }
  // LOGICA DISTRIBUICAO E COMERCIO DE PRODUTOS ALIMENTICIOS
  if (cnpj.startsWith('20237620')) {
    return addDays(emitDate, 15)
  }
  // LOG-IN (TVV)
  if (cnpj.startsWith('02639850')) {
    return addDays(emitDate, 30)
  }
  // MINERACAO CARAIBA S/A
  if (cnpj.startsWith('42509257')) {
    return addDays(emitDate, 45)
  }
  // PLACAS DO BRASIL
  if (cnpj.startsWith('14792934')) {
    // if emit_date >= 01 and <= 15 set to day 25 of next month
    if (emitDate.getDate() >= 1 && emitDate.getDate() <= 15) {
      return setDate(addMonths(emitDate, 1), 25)
    }
    // if emit_date >= 16 and <= 31 set to day 10 of next 2 months
    if (emitDate.getDate() >= 16 && emitDate.getDate() <= 31) {
      return setDate(addMonths(emitDate, 2), 10)
    }
  }
  // RAIZEN S.A. / BIOENERGIA / RZ AGRÍCOLA / BIOSEV
  if (
    cnpj.startsWith('33453598') ||
    cnpj.startsWith('08070508') ||
    cnpj.startsWith('09538958') ||
    cnpj.startsWith('09538989') ||
    cnpj.startsWith('15527906') ||
    cnpj.startsWith('18734168') ||
    cnpj.startsWith('49213747')
  ) {
    return addDays(emitDate, 30)
  }
  // RT DISTRIBUIDORA DE ALIMENTOS EIRELI (D F A)
  if (cnpj.startsWith('06889985')) {
    return addDays(emitDate, 20)
  }
  // SMURFIT KAPPA DO BRASIL INDUSTRIA DE EMBALAGENS
  if (cnpj.startsWith('23524952')) {
    // if emit_date >= 24 and <= 03 set to day 03 of next month
    if (
      (emitDate.getDate() >= 24 && emitDate.getDate() <= 31) ||
      (emitDate.getDate() >= 1 && emitDate.getDate() <= 3)
    ) {
      let increaseMonths = 2
      if (emitDate.getDate() <= 3) {
        increaseMonths = 1
      }
      return setDate(addMonths(emitDate, increaseMonths), 3)
    }
    // if emit_date >= 04 and <= 03 set to day 13 of next month
    if (emitDate.getDate() >= 4 && emitDate.getDate() <= 13) {
      return setDate(addMonths(emitDate, 1), 13)
    }
    // if emit_date >= 14 and <= 03 set to day 23 of next month
    if (emitDate.getDate() >= 14 && emitDate.getDate() <= 23) {
      return setDate(addMonths(emitDate, 1), 23)
    }
  }
  // SONOCO DO BRASIL LTDA
  if (cnpj.startsWith('00496586')) {
    return addDays(emitDate, 30)
  }
  // SUZANO PAPEL E CELULOSE / PORTOCEL
  if (cnpj.startsWith('16404287') || cnpj.startsWith('28497394')) {
    // if emit_date >= 01 and <= 09 set to day 10 of next month
    if (emitDate.getDate() >= 1 && emitDate.getDate() <= 9) {
      return setDate(addMonths(emitDate, 1), 10)
    }
    // if emit_date >= 10 and <= 19 set to day 20 of next month
    if (emitDate.getDate() >= 10 && emitDate.getDate() <= 19) {
      return setDate(addMonths(emitDate, 1), 20)
    }
    // if emit_date >= 20 and <= 31 set to day 01 of next month
    if (emitDate.getDate() >= 20 && emitDate.getDate() <= 31) {
      return setDate(addMonths(emitDate, 2), 1)
    }
  }
  // TROMBINI EMBALAGENS
  if (cnpj.startsWith('11252642')) {
    return addDays(emitDate, 30)
  }
  // WEG EQUIP ELETRICOS S A
  if (cnpj.startsWith('07175725') || cnpj.startsWith('10885321') || cnpj.startsWith('84584994')) {
    return addDays(emitDate, 30)
  }

  // Bpg Av Mofarrej Empreendimentos
  if (cnpj.startsWith('30435310')) {
    return addDays(emitDate, 30)
  }

  // Perfect Wave Pool S/a
  if (cnpj.startsWith('40051181')) {
    return addDays(emitDate, 30)
  }

  // Alimaq Mix Maquinas E Ferramentas Ltda
  if (cnpj.startsWith('07485336')) {
    return addDays(emitDate, 15)
  }

  // Rumo Malha Sul S.a
  if (cnpj.startsWith('01258944')) {
    return addDays(emitDate, 30)
  }

  // Terraverde Maquinas Agricolas Ltda
  if (cnpj.startsWith('09282594')) {
    return addDays(emitDate, 15)
  }

  // Contrex Engenharia E Servicos Ltda
  if (cnpj.startsWith('03644204')) {
    const date = addDays(emitDate, 45)
    if (isTuesday(date) || isThursday(date)) {
      return date
    }
    const dateTuesday = nextTuesday(date)
    const dateThursday = nextThursday(date)
    const diffTuesday = Math.abs(dateTuesday.getTime() - date.getTime())
    const diffThursday = Math.abs(dateThursday.getTime() - date.getTime())

    if (diffTuesday < diffThursday) return dateTuesday
    return dateThursday
  }

  // Energisa Minas Rio - Distribuidora De Energia S.a.
  if (cnpj.startsWith('19527639')) {
    return addDays(emitDate, 30)
  }

  // Sobre-rodas - Comercio E Equipamentos Ltda.
  if (cnpj.startsWith('28478667')) {
    return addDays(emitDate, 7)
  }

  // Metso Brasil Industria E Comercio Ltda
  if (cnpj.startsWith('16622284')) {
    const date = addDays(emitDate, 30)

    // verify if date is 26
    if (date.getDate() === 26) {
      return date
    }

    // else set to next 26
    if (date.getDate() < 26) {
      return setDate(date, 26)
    }

    if (date.getDate() > 26) {
      return setDate(addMonths(date, 1), 26)
    }
  }

  // General Roller Equipamentos Industriais Ltda
  if (cnpj.startsWith('05348791')) {
    return addDays(emitDate, 10)
  }

  // Energisa Sergipe - Distribuidora De Energia S.a
  if (cnpj.startsWith('13017462')) {
    return addDays(emitDate, 30)
  }

  // Vix Logistica S/a
  if (cnpj.startsWith('32681371')) {
    return addDays(emitDate, 30)
  }

  // Cmpc Celulose Riograndense Ltda
  if (cnpj.startsWith('11234954')) {
    return addDays(emitDate, 30)
  }

  // Energisa Tocantins Distribuidora De Energia S.a.
  if (cnpj.startsWith('25086034')) {
    return addDays(emitDate, 30)
  }

  // Energisa Mato Grosso - Distribuidora De Energia S.a.
  if (cnpj.startsWith('03467321')) {
    return addDays(emitDate, 30)
  }

  // Energisa Minas Rio - Distribuidora De Energia S.a.
  if (cnpj.startsWith('19527639')) {
    return addDays(emitDate, 30)
  }

  // Brametal Sul Metalurgica Ltda
  if (cnpj.startsWith('11145209')) {
    return addDays(emitDate, 30)
  }

  // Bracell Sp Celulose Ltda
  if (cnpj.startsWith('53943098')) {
    return addDays(emitDate, 30)
  }

  // Msfc Florestal Ltda
  if (cnpj.startsWith('49683306')) {
    return addDays(emitDate, 30)
  }

  if (cnpj.startsWith('75688824700')) {
    return addDays(emitDate, 1)
  }

  // Energisa Mato Grosso Do Sul - Distribuidora De Energia S.a.
  if (cnpj.startsWith('15413826')) {
    return addDays(emitDate, 30)
  }

  // Empresa Litoranea De Transmissao De Energia S.a. - Elte
  if (cnpj.startsWith('20626892')) {
    return addDays(emitDate, 30)
  }

  // Sonoco Tubos E Cones Ltda
  if (cnpj.startsWith('13795178')) {
    return addDays(emitDate, 30)
  }

  // Klabin Da Amazonia Solucoes Em Embalagens De Papel Ltda.
  if (cnpj.startsWith('04398525')) {
    return addDays(emitDate, 30)
  }

  // Floraplac Mdf Ltda
  if (cnpj.startsWith('09256139')) {
    return addDays(emitDate, 30)
  }

  if (cnpj.startsWith('61478897')) {
    return addDays(emitDate, 7)
  }

  if (cnpj.startsWith('11503151')) {
    return addDays(emitDate, 15)
  }

  // Hersa Engenharia E Servicos Ltda
  if (cnpj.startsWith('01376473')) {
    const date = addDays(emitDate, 30)
    if (isTuesday(date) || isThursday(date)) {
      return date
    }
    const dateTuesday = nextTuesday(date)
    const dateThursday = nextThursday(date)
    const diffTuesday = Math.abs(dateTuesday.getTime() - date.getTime())
    const diffThursday = Math.abs(dateThursday.getTime() - date.getTime())

    if (diffTuesday < diffThursday) return dateTuesday
    return dateThursday
  }

  // Concessionaria Allegra Pacaembu Spe S/a
  if (cnpj.startsWith('30262440')) {
    return addDays(emitDate, 30)
  }

  // Bracell Sp Florestal Ltda
  if (cnpj.startsWith('50640054')) {
    return addDays(emitDate, 30)
  }

  // Nova Limp Comercio De Embalagens E Descartaveis Ltda
  if (cnpj.startsWith('04681311')) {
    return addDays(emitDate, 30)
  }

  // Abecom Rolamentos E Produtos De Borracha Ltda
  if (cnpj.startsWith('61478897')) {
    return addDays(emitDate, 7)
  }

  // Grandis Agroflorestal S/a
  if (cnpj.startsWith('41757442')) {
    return addDays(emitDate, 15)
  }

  // Rumo Malha Paulista S.a.
  if (cnpj.startsWith('02502844')) {
    return addDays(emitDate, 30)
  }

  // Alpargatas S.a.
  if (cnpj.startsWith('61079117')) {
    return addDays(emitDate, 30)
  }

  // Rumo Malha Sul S.a
  if (cnpj.startsWith('01258944')) {
    return addDays(emitDate, 30)
  }

  // Vix Transportes Dedicados Ltda
  if (cnpj.startsWith('09452900')) {
    return addDays(emitDate, 30)
  }

  // Bracell Bahia Florestal Ltda
  if (cnpj.startsWith('15692999')) {
    return addDays(emitDate, 30)
  }

  // Videojet Do Brasil Comercio De Equipamentos Para Codificacao Industrial Ltda
  if (cnpj.startsWith('00028876')) {
    return addDays(emitDate, 15)
  }

  // Rumo Malha Norte S.a
  if (cnpj.startsWith('24962466')) {
    return addDays(emitDate, 30)
  }

  // Polar 1 Construcao Naval Spe Ltda
  if (cnpj.startsWith('45618886')) {
    return addDays(emitDate, 30)
  }

  // Ld Celulose S.a.
  if (cnpj.startsWith('29627430')) {
    return addDays(emitDate, 45)
  }

  // Associacao Gestora Da Ferrovia Interna Do Porto De Santos
  if (cnpj.startsWith('50449414')) {
    return addDays(emitDate, 30)
  }

  // Rumo Malha Oeste S.a.
  if (cnpj.startsWith('39115514')) {
    return addDays(emitDate, 30)
  }

  // Sopetra Rolamentos E Pecas Ltda
  if (cnpj.startsWith('60835162')) {
    return addDays(emitDate, 30)
  }

  // Sopetra Rolamentos E Pecas Ltda
  if (cnpj.startsWith('60835162')) {
    const date = addDays(emitDate, 35)

    if (date.getDate() > 25 && date.getDate() < 31 && date.getDate() < 7) {
      return setDate(date, 7)
    }

    if (date.getDate() > 7 && date.getDate() < 14) {
      return setDate(date, 14)
    }

    if (date.getDate() > 14 && date.getDate() < 25) {
      return setDate(date, 25)
    }
  }

  // Oliveira Ind. E Com. De Retentores Ltda
  if (cnpj.startsWith('31482789')) {
    return addDays(emitDate, 15)
  }

  // Eman Servicos Industriais Ltda
  if (cnpj.startsWith('17368228')) {
    return addDays(emitDate, 30)
  }

  // Auto Defesa
  if (cnpj.startsWith('30609644')) {
    return addDays(emitDate, 15)
  }

  if (cnpj.startsWith('11503151')) {
    return addDays(emitDate, 15)
  }

  // Hecta Agro
  if (cnpj.startsWith('32423187')) {
    return addDays(emitDate, 15)
  }

  // OKS
  if (cnpj.startsWith('18773760')) {
    return addDays(emitDate, 7)
  }

  // Oliveira Retentores
  if (cnpj.startsWith('31482789')) {
    return addDays(emitDate, 15)
  }

  // Vila Ervas
  if (cnpj.startsWith('05418260')) {
    return addDays(emitDate, 10)
  }

  return addDays(emitDate, 30)
}
