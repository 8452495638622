import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Box,
  Spinner,
  useColorModeValue,
  Center,
  Text,
} from '@chakra-ui/react'
import { useGetOneFreight } from '../services/endpoints/freights'
import { ListMdfe } from '../pages/freights/show/cards/list-mdfe'
import { ListFreightContract } from '../pages/freights/show/cards/list-freight-contracts'
import { ListCte } from '../pages/freights/show/cards/list-cte'
import { ListCiotAtFreight } from '../pages/freights/show/cards/list-ciot'
import { displayAdaptable } from '../services/utils/tableAdaptable'
import { useListMdfesByFreightId } from '../services/endpoints/datamex/listMdfesByFreightId'

interface FreightDocumentsProps {
  freight_id: string
}

export function FreightDocuments({ freight_id }: FreightDocumentsProps): JSX.Element {
  const bg = useColorModeValue('white', 'gray.800')
  const {
    data: freight,
    isLoading,
    isFetching,
  } = useGetOneFreight({
    freight_id,
    relations: [
      'creator',
      'origin',
      'destination',
      'client',
      'client_contact',
      'client_feedback',
      'seller',
      'motorist',
      'attachments',
      'motorist.address_city',
      'motorist.address_city.state',
      'motorist.driver_license',
      'vehicle',
      'vehicle.owner',
      'vehicle.antt_owner',
      'vehicle.owner.city',
      'vehicle.vehicle_category',
      'vehicle.vehicle_body',
      'vehicle_categories',
      'vehicle_bodies',
      'trailer',
      'trailer.owner',
      'trailer.owner.city',
      'trailer.vehicle_body',
      'cargo',
      'rate',
      'nps_motorists',
      'ctes',
      'freight_expenses',
      'monitoring_occurrences',
      'monitoring_occurrences.reason',
      'monitoring_occurrences.user',
    ],
  })
  return (
    <>
      {isLoading || isFetching ? (
        <Center>
          <Spinner size="lg" />
        </Center>
      ) : (
        freight && (
          <Box mt="4" d={displayAdaptable}>
            <ListCte freight={freight} bg={bg} />
            <ListMdfe freight={freight} bg={bg} />
            <ListCiotAtFreight freight={freight} />
            <ListFreightContract freight={freight} bg={bg} />
          </Box>
        )
      )}
    </>
  )
}

interface FreightDocumentsModalProps {
  freight_id: string
}

export function FreightDocumentsModal({ freight_id }: FreightDocumentsModalProps): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { data: freight } = useGetOneFreight({ freight_id, relations: ['ctes', 'freight_contracts'] })
  const { data: mdfes } = useListMdfesByFreightId(freight_id)

  return (
    <>
      <Flex justifyContent="center" alignItems="center" direction="column" gridGap="2">
        {freight?.ctes?.length === 0 && freight?.freight_contracts?.length === 0 && mdfes?.length === 0 ? (
          <Flex justifyContent="center">
            <Text textAlign="center" justifyContent="center">
              Nenhum documento no frete
            </Text>
          </Flex>
        ) : (
          <Button onClick={onOpen} colorScheme="orange" size="xs" cursor="zoom-in">
            Ver: CTE({freight?.ctes?.length}) | MDFe(
            {mdfes?.length}) | CF({freight?.freight_contracts?.length})
          </Button>
        )}
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose} size="5xl" closeOnOverlayClick={false} scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Documentos do frete #{freight?.freight_number} </ModalHeader>
          <ModalCloseButton />
          <ModalBody mb="6">
            <FreightDocuments freight_id={freight_id} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
