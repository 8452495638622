/* eslint-disable @typescript-eslint/no-unused-vars */
import { Divider, Grid, GridItem, Heading, useToast } from '@chakra-ui/react'
import { useCallback, useEffect, useState } from 'react'
import { DeepMap, FieldError, FieldValues, UseFormSetValue } from 'react-hook-form'
import { DropzoneImageUpload } from '../../../../../components/DropzoneImageUpload'
import {
  AutocompleteAsync,
  AutocompleteMulti,
  Input,
  InputNumberMask,
  Select,
  SelectOption,
  TextArea,
} from '../../../../../components/form'
import { toastError } from '../../../../../config/error/toastError'
import { CteIssuanceRequestTypeFormatted } from '../../../../../services/endpoints/freights/GetCteIssuanceRequest'
import { getFreightByNumber } from '../../../../../services/endpoints/freights/getFreightByNumber'
import { fetchMotorist } from '../../../../../services/endpoints/motorists/getMotorist'
import { searchMotoristByName } from '../../../../../services/endpoints/motorists/searchMotoristByName'
import { convertToNumber } from '../../../../../services/utils/convertMoneyToNumber'

type CreateOrUpdateCteIssuanceRequestFormType = {
  errors: DeepMap<FieldValues, FieldError>
  setValue: UseFormSetValue<FieldValues>
  initial_value?: CteIssuanceRequestTypeFormatted
}

type CustomFormValuesType = {
  client?: {
    label: string
  }
  client_contact?: {
    label: string
  }
  seller?: {
    label: string
  }
  service_price?: number
  agreed_price?: number

  vehicle_categories?: SelectOption[]

  motorist?: {
    motorist_infos: {
      label: string
      value: string
    }
    license_plates?: SelectOption[]
  }
}

type TollDataType = {
  has_toll?: string
  toll_value?: number
  deduct_motorist_total?: string
}

export function CreateOrUpdateCteIssuanceRequestForm({
  errors,
  setValue,
  initial_value,
}: CreateOrUpdateCteIssuanceRequestFormType): JSX.Element {
  const toast = useToast()
  const [freightNumber, setFreightNumber] = useState<string>('')
  const [customFormValues, setCustomFormValues] = useState<CustomFormValuesType>()
  const [tollData, setTollData] = useState<TollDataType>()

  const getFormData = useCallback(
    async (freight_number?: string): Promise<void> => {
      let freight
      let motorist

      if (freight_number) {
        toast({
          title: 'Buscando dados do frete!',
          status: 'info',
          position: 'top',
          isClosable: true,
          duration: 3000,
        })

        freight = await getFreightByNumber(freight_number)

        if (freight) {
          setValue('freight_id', freight.id)
          motorist = freight.motorist

          toast({
            title: 'Frete encontrado com sucesso!',
            status: 'success',
            position: 'top',
            isClosable: true,
            duration: 3000,
          })
        }
      } else {
        freight = initial_value?.freight
        motorist = initial_value?.motorist
      }

      if (freight) {
        const customFormValuesData: CustomFormValuesType = {
          client: {
            label: freight.client?.name,
          },
          client_contact: {
            label: freight.client_contact?.name || 'Não possui contato',
          },
          seller: {
            label: freight.seller?.name || 'Não possui vendedor',
          },
          service_price: Number(freight.service_price),
          agreed_price: Number(freight.agreed_price),
        }

        if (freight.vehicle_categories && freight.vehicle_categories.length > 0) {
          const vehicleCategories = freight.vehicle_categories.map(category => ({
            label: String(category.name),
            value: String(category.id),
          }))

          Object.assign(customFormValuesData, {
            vehicle_categories: vehicleCategories,
          })
        }

        if (motorist) {
          const motoristVehicleLiscensePlates = motorist.vehicles.map(vehicle => ({
            label: String(vehicle.license_plate),
            value: String(vehicle.id),
          }))

          Object.assign(customFormValuesData, {
            motorist: {
              motorist_infos: {
                label: motorist.name,
                value: motorist.id,
              },
              license_plates: motoristVehicleLiscensePlates,
            },
          })
        } else {
          toastError({ toast, error: 'Frete não possui motorista' })
        }

        setCustomFormValues(customFormValuesData)
      }
    },
    [initial_value, setValue, toast],
  )

  useEffect(() => {
    if (
      (freightNumber && freightNumber.length >= 4) ||
      (initial_value && initial_value.freight?.freight_number)
    ) {
      getFormData(freightNumber)
    }
  }, [freightNumber, toast, initial_value, getFormData])

  useEffect(() => {
    if (initial_value) {
      setTollData({
        has_toll: initial_value.has_toll ? 'yes' : 'no',
        deduct_motorist_total: initial_value.deduct_motorist_total ? 'yes' : 'no',
        toll_value: initial_value.toll_value,
      })
    }
  }, [initial_value])

  return (
    <>
      <Heading size="md" fontWeight="normal" mb="5">
        Informações do frete
      </Heading>

      <Grid templateColumns="repeat(12, 1fr)" gap="3">
        <GridItem colSpan={[12, 6, 2]} mr={2}>
          <Input
            label="Número do frete"
            name="freight_number"
            error={errors.freight_number}
            placeholder="Número do frete"
            type="number"
            autoFocus
            setValue={setValue}
            isDisabled={!!initial_value?.freight?.freight_number}
            onChange={e => setFreightNumber(e.target.value)}
            initialValue={initial_value?.freight?.freight_number || freightNumber}
            isRequired
          />
        </GridItem>

        <GridItem colSpan={[12, 6, 5]} mr={2}>
          <AutocompleteAsync
            label="Motorista"
            name="motorist"
            error={errors.motorist}
            placeholder="Motorista"
            setValue={setValue}
            loadOptions={searchMotoristByName}
            onSelectOption={async motorist => {
              if (motorist.value) {
                const motoristData = await fetchMotorist(String(motorist.value))

                if (motoristData) {
                  const motoristVehicleLiscensePlates = motoristData.vehicles.map(vehicle => ({
                    label: String(vehicle.license_plate),
                    value: String(vehicle.id),
                  }))

                  setCustomFormValues(prevState => {
                    return {
                      ...prevState,
                      motorist: {
                        motorist_infos: {
                          label: motoristData.name,
                          value: motoristData.id,
                        },
                        license_plates: motoristVehicleLiscensePlates,
                      },
                    }
                  })
                }
              }
            }}
            initialValue={customFormValues?.motorist?.motorist_infos}
            isDisabled={!customFormValues}
          />
        </GridItem>

        <GridItem colSpan={[12, 6, 3]} mr={2}>
          <Select
            label="Placa do veículo (Motorista)"
            name="motorist_vehicle_id"
            error={errors.motorist_vehicle_id}
            options={
              customFormValues?.motorist?.license_plates?.length
                ? [{ label: '', value: '' }, ...customFormValues?.motorist?.license_plates]
                : []
            }
            setValue={setValue}
            initialValue={initial_value?.motorist_vehicle?.id}
            isDisabled={!customFormValues}
          />
        </GridItem>

        <GridItem colSpan={[12, 6, 2]} mr={2}>
          <Select
            label="Desconto buonny"
            name="discount_buonny"
            error={errors.discount_buonny}
            setValue={setValue}
            options={[
              { label: 'Não', value: '0' },
              { label: 'R$ 35', value: '35' },
              { label: 'R$ 45', value: '45' },
            ]}
            isDisabled={!customFormValues}
            initialValue={String(initial_value?.discount_buonny)}
            isRequired
          />
        </GridItem>

        <GridItem colSpan={[12, 6, 4]} mr={2}>
          <Input
            title="Cliente"
            label="Cliente"
            name="client"
            error={errors.client}
            placeholder="Cliente"
            setValue={setValue}
            initialValue={customFormValues?.client?.label}
            isDisabled
          />
        </GridItem>

        <GridItem colSpan={[12, 6, 4]} mr={2}>
          <Input
            title="Solicitante"
            label="Solicitante"
            name="client_contact"
            error={errors.client_contact}
            placeholder="Solicitante"
            setValue={setValue}
            initialValue={customFormValues?.client_contact?.label}
            isDisabled
          />
        </GridItem>

        <GridItem colSpan={[12, 6, 4]} mr={2}>
          <Input
            title="Vendedor"
            label="Vendedor"
            name="seller"
            error={errors.seller}
            placeholder="Vendedor"
            setValue={setValue}
            initialValue={customFormValues?.seller?.label}
            isDisabled
          />
        </GridItem>

        <GridItem colSpan={[12, 6, 3]} mr={2}>
          <InputNumberMask
            label="Valor do frete"
            name="service_price"
            error={errors.service_price}
            placeholder="Valor do frete"
            initialValue={customFormValues?.service_price}
            setValue={setValue}
            isCashBRL
            isDisabled
          />
        </GridItem>

        <GridItem colSpan={[12, 6, 3]} mr={2}>
          <InputNumberMask
            label="Valor motorista"
            name="agreed_price"
            error={errors.agreed_price}
            placeholder="Valor motorista"
            initialValue={customFormValues?.agreed_price}
            setValue={setValue}
            isCashBRL
            isDisabled
          />
        </GridItem>

        <GridItem colSpan={[12, 4, 6]}>
          <AutocompleteMulti
            name="vehicle_categories"
            label="Tipo de veículos"
            error={errors.vehicle_categories}
            options={[]}
            setValue={setValue}
            isDisabled
            initialValue={
              customFormValues?.vehicle_categories &&
              customFormValues?.vehicle_categories.length > 0 &&
              customFormValues?.vehicle_categories.every(category => category.label !== 'undefined')
                ? customFormValues?.vehicle_categories
                : undefined
            }
          />
        </GridItem>
      </Grid>

      <Divider my="4" />

      <Heading size="md" fontWeight="normal" mb="5">
        Informações sobre pedágio
      </Heading>

      <Grid templateColumns="repeat(12, 1fr)" gap="3">
        <GridItem colSpan={[12, 6, 3]} mr={2}>
          <Select
            label="Possui pedágio?"
            name="has_toll"
            setValue={setValue}
            error={errors.has_toll}
            options={[
              {
                label: 'Não',
                value: 'no',
              },
              {
                label: 'Sim',
                value: 'yes',
              },
            ]}
            onSelectOption={value => {
              setTollData(prevState => ({
                ...prevState,
                has_toll: value.value,
              }))
            }}
            isDisabled={!customFormValues}
            initialValue={initial_value?.has_toll ? 'yes' : 'no'}
          />
        </GridItem>

        {tollData?.has_toll === 'yes' && (
          <GridItem colSpan={[12, 6, 3]} mr={2}>
            <InputNumberMask
              label="Valor do pedágio"
              name="toll_value"
              error={errors.toll_value}
              placeholder="Valor do pedágio"
              initialValue={Number(initial_value?.toll_value)}
              setValue={setValue}
              onChange={value => {
                setTollData(prevState => ({
                  ...prevState,
                  toll_value: convertToNumber(value.target.value),
                }))
              }}
              isCashBRL
            />
          </GridItem>
        )}

        {tollData?.has_toll === 'yes' && (
          <GridItem colSpan={[12, 6, 4]} mr={2}>
            <Select
              label="Descontar valor do motorista?"
              name="deduct_motorist_total"
              setValue={setValue}
              error={errors.deduct_motorist_total}
              options={[
                {
                  label: 'Não',
                  value: 'no',
                },
                {
                  label: 'Sim',
                  value: 'yes',
                },
              ]}
              isDisabled={!customFormValues}
              initialValue={initial_value?.deduct_motorist_total ? 'yes' : 'no'}
            />
          </GridItem>
        )}

        {tollData?.has_toll === 'yes' && (
          <GridItem colSpan={[12, 6, 2]} mr={2}>
            <Select
              label="Tag do pedágio"
              name="tag_toll"
              error={errors.tag_toll}
              setValue={setValue}
              options={[
                { label: '', value: '' },
                { label: 'Sem Parar', value: 'Sem Parar' },
                { label: 'ConectCar', value: 'ConectCar' },
              ]}
              isDisabled={!customFormValues}
              initialValue={String(initial_value?.tag_toll)}
            />
          </GridItem>
        )}
      </Grid>

      {!initial_value && (
        <>
          <Divider my="4" />

          <Grid templateColumns="repeat(12, 1fr)" gap="3">
            <GridItem colSpan={[12, 6, 12]}>
              <DropzoneImageUpload
                name="attachment_file"
                setValue={setValue}
                displayMode="list"
                multiple
                label="Nota fiscal"
              />
            </GridItem>
          </Grid>
        </>
      )}

      <Divider my="4" />

      <Grid templateColumns="repeat(12, 1fr)" gap="3">
        <GridItem colSpan={[12, 6, 12]}>
          <TextArea
            name="observation"
            h="150"
            error={errors.observation}
            label="Observação"
            setValue={setValue}
            isDisabled={!customFormValues}
            initialValue={initial_value?.observation}
          />
        </GridItem>
      </Grid>
    </>
  )
}
