import { format } from 'date-fns'
import { useQuery, UseQueryResult } from 'react-query'
import { SelectOption } from '../../../components/form'
import { apiServer } from '../../api'
import { BalanceRequestType } from '../../types/BalanceRequestType'
import { BalanceRequestStatusEnum } from '../../types/EnumTypes'
import { formatBalanceRequestStatus } from '../../utils/formatBalanceRequestStatus'
import parseCurrency from '../../utils/parseCurrency'

type BalanceRequestTypeFormatted = BalanceRequestType & {
  formatted_status: string
  balance_formatted: string
  total_value_formatted: string
  discount_buonny_formatted: string
}

type ListBalanceRequestResponse = {
  total: number
  data: BalanceRequestTypeFormatted[]
}

export type FilterBalanceRequestsType = {
  freight_number?: string
  status?: BalanceRequestStatusEnum
  creator_id?: SelectOption
  motorist_id?: SelectOption
  initial_date?: string
  final_date?: string
  page?: number
}

async function listBalanceRequest(params: FilterBalanceRequestsType) {
  Object.assign(params, {
    ...params,
    motorist_id: params.motorist_id?.value,
    creator_id: params.creator_id?.value,
  })

  const { data: response } = await apiServer.get(`/balance-requests`, {
    params,
  })

  const { data } = response
  const { total } = data

  const formattedBalanceRequests = data.data.map((balanceRequest: BalanceRequestType) => {
    return {
      ...balanceRequest,
      discount_buonny_formatted: parseCurrency(data.discount_buonny),
      formatted_status: formatBalanceRequestStatus(balanceRequest.status),
      balance_formatted: parseCurrency(balanceRequest.total_value * 0.3),
      total_value_formatted: parseCurrency(balanceRequest.total_value),
      created_at: format(new Date(balanceRequest.created_at), 'dd/MM/yy HH:mm'),
    }
  })

  return { total, data: formattedBalanceRequests }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useGetAllBalanceRequest(
  params: FilterBalanceRequestsType,
): UseQueryResult<ListBalanceRequestResponse> {
  return useQuery(['balance-requests', params], () => listBalanceRequest(params), {
    staleTime: 1000 * 60 * 10,
  })
}
