import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { forEach } from 'lodash'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { FaWhatsapp } from 'react-icons/fa'
import * as yup from 'yup'
import { DropzoneImageUpload } from '../../../../components/DropzoneImageUpload'
import {
  AutocompleteAsync,
  Input,
  InputNumberMask,
  Select,
  SelectOption,
  TextArea,
} from '../../../../components/form'
import { toastError } from '../../../../config/error/toastError'
import { searchMotoristByName } from '../../../../services/endpoints/motorists/searchMotoristByName'
import { IChecklistRiskAnalysisResponse } from '../../../../services/endpoints/risk-analysis/checklist-risk-analysis/getChecklistRiskAnalysis'
import { usePostNotifyRiskAnalysis } from '../../../../services/endpoints/risk-analysis/checklist-risk-analysis/usePostNotifyRiskAnalysis'
import { RiskAnalysisOneFormatted } from '../../../../services/endpoints/risk-analysis/getOneRiskAnalysis'
import { usersAutoComplete } from '../../../../services/endpoints/users/usersAutoComplete'
import { MotoristAttachmentType } from '../../../../services/types'
import formatCPFAndCNPJ from '../../../../services/utils/formatCPFAndCNPJ'

interface NotifyRiskAnalysisModalProps {
  riskAnalysis: RiskAnalysisOneFormatted
  checklistRiskAnalysis?: IChecklistRiskAnalysisResponse[]
}

type SendNotificaionType = {
  freight_number: string
  client: string
  analyst: SelectOption
  seller: SelectOption
  cargo_name: string
  cargo_weight: number
  total_value: string
  origin: string
  destination: string
  distance: string
  motorist: SelectOption
  motorist_cpf: string
  is_victimology: string
  motorist_is_recurrent: string
  is_tracker: string
  is_locator: string
  is_antt_active: string
  is_inspection: string
  reason: string
  observations?: string
  attachment_files?: File[]
}

const notifyRiskAnalysisFormSchema = yup.object().shape({
  freight_number: yup.string().required('Campo obrigatório'),
  client: yup.string().required('Campo obrigatório'),
  analyst: yup.object().shape({}).required('Campo obrigatório'),
  seller: yup.object().shape({}).required('Campo obrigatório'),
  cargo_name: yup.string().required('Campo obrigatório'),
  cargo_weight: yup.number().required('Campo obrigatório'),
  total_value: yup.string().required('Campo obrigatório'),
  origin: yup.string().required('Campo obrigatório'),
  destination: yup.string().required('Campo obrigatório'),
  distance: yup.string().required('Campo obrigatório'),
  motorist: yup.object().shape({}).required('Campo obrigatório'),
  motorist_cpf: yup.string().required('Campo obrigatório'),
  is_victimology: yup.string().required('Campo obrigatório'),
  motorist_is_recurrent: yup.string().required('Campo obrigatório'),
  is_tracker: yup.string().required('Campo obrigatório'),
  is_locator: yup.string().required('Campo obrigatório'),
  is_antt_active: yup.string().required('Campo obrigatório'),
  is_inspection: yup.string().required('Campo obrigatório'),
  reason: yup.string().required('Campo obrigatório'),
  observations: yup.string(),
})

export function NotifyRiskAnalysisModal({
  riskAnalysis,
  checklistRiskAnalysis,
}: NotifyRiskAnalysisModalProps): JSX.Element {
  const [wannaAddObservations, setWannaAddObservations] = useState('no')

  const toast = useToast()
  const { isOpen, onClose, onOpen } = useDisclosure()

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(notifyRiskAnalysisFormSchema),
  })

  const { mutateAsync: postNotifyRiskAnalysis, isLoading: isLoadingPostNotifyRiskAnalysis } =
    usePostNotifyRiskAnalysis()

  function motoristHasCrlv(
    attachments: MotoristAttachmentType[] | undefined,
  ): MotoristAttachmentType | undefined {
    if (!attachments) return undefined
    const hasCrlv = attachments.find(attachment => attachment.type.toLowerCase() === 'crlv')
    return hasCrlv
  }

  function formattedCPF(value: string | undefined): string | undefined {
    const formattedCpf = formatCPFAndCNPJ(value)

    if (formattedCpf) {
      return formattedCpf
    }
    return undefined
  }

  function motoristIsRecurrent(total_freights: number | undefined): string {
    if (total_freights && total_freights > 1) {
      return `Sim, o motorista já realizou ${total_freights} fretes.`
    }

    if (total_freights && total_freights === 1) {
      return 'Não, o motorista realizou somente 1 frete.'
    }

    return 'Não, o motorista nunca realizou nenhum frete.'
  }

  const handlePostNotification = async (data: SendNotificaionType) => {
    try {
      const formData = new FormData()

      const checkLists = checklistRiskAnalysis
        ?.map(checkList => {
          const checkListType = checkList.checkType === 'video' ? 'Ligação de vídeo' : 'Inspeção Veicular'
          return `${checkListType}: *${checkList.status}*`
        })
        .join('\n')

      const motoristCrlv = motoristHasCrlv(riskAnalysis.motorist.attachments)

      if (motoristCrlv && motoristCrlv.attachment_file_url) {
        formData.append('motorist_crlv', motoristCrlv.attachment_file_url)
      }

      if (data.attachment_files && data.attachment_files.length > 0) {
        forEach(data.attachment_files, async file => {
          formData.append('attachment_files', file)
        })
      }

      formData.append('risk_analysis_id', riskAnalysis.id)
      formData.append('freight_number', data.freight_number)
      formData.append('client', data.client)
      formData.append('analyst', data.analyst.label)
      formData.append('seller', data.seller.label)
      formData.append('cargo_name', data.cargo_name)
      formData.append('cargo_weight', String(data.cargo_weight))
      formData.append('total_value', data.total_value)
      formData.append('origin', data.origin)
      formData.append('destination', data.destination)
      formData.append('distance', data.distance)
      formData.append('motorist', data.motorist.label)
      formData.append('motorist_cpf', data.motorist_cpf)
      formData.append('is_victimology', data.is_victimology)
      formData.append('motorist_is_recurrent', data.motorist_is_recurrent)
      formData.append('is_tracker', data.is_tracker)
      formData.append('is_locator', data.is_locator)
      formData.append('is_antt_active', data.is_antt_active)
      formData.append('is_inspection', data.is_inspection)
      formData.append('reason', data.reason)
      formData.append('observations', data.observations ?? '')
      formData.append('checklists', checkLists ?? 'Não informado')

      await postNotifyRiskAnalysis({ data: formData })

      toast({
        title: 'Notificação enviada com sucesso!',
        status: 'success',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      })

      reset()

      onClose()
    } catch (error) {
      toastError({
        error,
        toast,
      })
    }
  }

  return (
    <>
      <Box>
        <Tooltip label="Aprovação de Risco Esporádico" hasArrow placement="top">
          <Button
            setValue={setValue}
            label="Notificar Zenilton"
            name="notify_zenilton"
            leftIcon={<FaWhatsapp />}
            onClick={onOpen}
          >
            Notificar Zenilton
          </Button>
        </Tooltip>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size="4xl">
        <ModalOverlay />
        <ModalContent>
          <Box p="4">
            <form onSubmit={handleSubmit(handlePostNotification)}>
              <Heading size="md" fontWeight="normal" mb={4}>
                Envio de notificação para Zenilton
              </Heading>
              <ModalCloseButton />

              <Divider my="4" />

              <Heading size="md" fontWeight="normal" mb="5">
                Informações do frete
              </Heading>

              <Grid templateColumns="repeat(12, 1fr)" gap="3">
                <GridItem colSpan={[12, 6, 3]} mr={2}>
                  <Input
                    label="Número do frete"
                    name="freight_number"
                    placeholder="Número do frete"
                    type="number"
                    setValue={setValue}
                    error={errors.freight_number}
                    isDisabled={!!riskAnalysis.freight.freight_number}
                    initialValue={riskAnalysis.freight.freight_number}
                    isInvalid={errors.freight_number}
                    isRequired
                  />
                </GridItem>

                <GridItem colSpan={[12, 6, 9]} mr={2}>
                  <Input
                    title="Cliente"
                    label="Cliente"
                    name="client"
                    error={errors.client}
                    placeholder="Cliente"
                    setValue={setValue}
                    initialValue={riskAnalysis.freight.client.name}
                    isDisabled
                  />
                </GridItem>

                <GridItem colSpan={[12, 6, 4]} mr={2}>
                  <AutocompleteAsync
                    label="Analista"
                    name="analyst"
                    error={errors.analyst}
                    placeholder="Analista"
                    setValue={setValue}
                    initialValue={
                      riskAnalysis.user
                        ? {
                            label: riskAnalysis.user.name,
                            value: riskAnalysis.user.id,
                          }
                        : undefined
                    }
                    loadOptions={usersAutoComplete}
                    isDisabled
                  />
                </GridItem>

                <GridItem colSpan={[12, 6, 4]} mr={2}>
                  <AutocompleteAsync
                    label="Vendedor"
                    name="seller"
                    error={errors.seller}
                    placeholder="Vendedor"
                    setValue={setValue}
                    initialValue={
                      riskAnalysis.freight.seller
                        ? {
                            label: riskAnalysis.freight.seller.name,
                            value: riskAnalysis.freight.seller.id,
                          }
                        : undefined
                    }
                    loadOptions={usersAutoComplete}
                    isDisabled
                  />
                </GridItem>
              </Grid>

              <Divider my="4" />

              <Heading size="md" fontWeight="normal" mb="5">
                Informações sobre a carga
              </Heading>

              <Grid templateColumns="repeat(12, 1fr)" gap="3">
                <GridItem colSpan={[12, 6, 4]} mr={2}>
                  <Input
                    label="Material"
                    name="cargo_name"
                    error={errors.cargo_name}
                    placeholder="Material"
                    setValue={setValue}
                    isDisabled
                    initialValue={riskAnalysis.freight.cargo?.name}
                  />
                </GridItem>

                <GridItem colSpan={[12, 6, 4]} mr={2}>
                  <InputNumberMask
                    label="Valor total"
                    name="total_value"
                    error={errors.total_value}
                    placeholder="Valor total"
                    setValue={setValue}
                    initialValue={Number(riskAnalysis.freight.cargo?.value)}
                    isCashBRL
                  />
                </GridItem>

                <GridItem colSpan={[12, 6, 4]} mr={2}>
                  <Input
                    label="Peso em KG"
                    name="cargo_weight"
                    error={errors.cargo_weight}
                    placeholder="Peso em KG"
                    setValue={setValue}
                    isDisabled
                    initialValue={riskAnalysis.freight.cargo?.weight}
                  />
                </GridItem>
              </Grid>

              <Divider my="4" />

              <Heading size="md" fontWeight="normal" mb="5">
                Origem e destino
              </Heading>

              <Grid templateColumns="repeat(12, 1fr)" gap="3">
                <GridItem colSpan={[12, 6, 5]} mr={2}>
                  <Input
                    label="Origem"
                    name="origin"
                    error={errors.origin}
                    placeholder="Origem"
                    setValue={setValue}
                    isDisabled
                    initialValue={riskAnalysis.freight.origin_city}
                  />
                </GridItem>

                <GridItem colSpan={[12, 6, 5]} mr={2}>
                  <Input
                    label="Destino"
                    name="destination"
                    error={errors.destination}
                    placeholder="Destino"
                    setValue={setValue}
                    isDisabled
                    initialValue={riskAnalysis.freight.destination_city}
                  />
                </GridItem>

                <GridItem colSpan={[12, 6, 2]} mr={2}>
                  <Input
                    label="Distância (KM)"
                    name="distance"
                    error={errors.distance}
                    placeholder="Distância em KM"
                    initialValue={riskAnalysis.freight.distance}
                    setValue={setValue}
                    isDisabled
                  />
                </GridItem>
              </Grid>

              <Divider my="4" />

              <Heading size="md" fontWeight="normal" mb="5">
                Informações sobre o motorista
              </Heading>

              <Grid templateColumns="repeat(12, 1fr)" gap="3">
                <GridItem colSpan={[12, 6, 5]} mr={2}>
                  <AutocompleteAsync
                    label="Motorista"
                    name="motorist"
                    error={errors.motorist}
                    placeholder="Motorista"
                    setValue={setValue}
                    loadOptions={searchMotoristByName}
                    initialValue={
                      riskAnalysis.motorist
                        ? { label: riskAnalysis.motorist.name, value: riskAnalysis.motorist.id }
                        : undefined
                    }
                    isDisabled
                  />
                </GridItem>

                <GridItem colSpan={[12, 6, 4]} mr={2}>
                  <Input
                    label="CPF do motorista"
                    name="motorist_cpf"
                    error={errors.motorist_cpf}
                    placeholder="CPF do motorista"
                    initialValue={formattedCPF(riskAnalysis.motorist.cpf)}
                    setValue={setValue}
                    isDisabled
                  />
                </GridItem>

                <GridItem colSpan={[12, 6, 3]} mr={2}>
                  <Select
                    label="Vitimologia?"
                    name="is_victimology"
                    setValue={setValue}
                    error={errors.is_victimology}
                    initialValue="no"
                    options={[
                      {
                        label: 'Não',
                        value: 'no',
                      },
                      {
                        label: 'Sim',
                        value: 'yes',
                      },
                    ]}
                  />
                </GridItem>

                <GridItem colSpan={[12, 6, 12]} mr={2}>
                  <Input
                    label="Motorista é recorrente?"
                    name="motorist_is_recurrent"
                    error={errors.motorist_is_recurrent}
                    setValue={setValue}
                    initialValue={motoristIsRecurrent(riskAnalysis.motorist.total_freights)}
                    isDisabled
                  />
                </GridItem>

                <GridItem colSpan={[12, 6, 12]} mr={2}>
                  <Alert
                    variant="subtle"
                    status={motoristHasCrlv(riskAnalysis.motorist.attachments) ? 'success' : 'warning'}
                    borderRadius="md"
                    justifyContent="space-between"
                    flexDirection={['column', 'row']}
                    gridGap="2"
                  >
                    <Flex alignItems="center">
                      <AlertIcon />
                      <AlertTitle mr={2} fontSize="lg">
                        {motoristHasCrlv(riskAnalysis.motorist.attachments)
                          ? 'O motorista possui CRLV anexado!'
                          : 'O motorista não possui CRLV anexado!'}
                      </AlertTitle>
                    </Flex>

                    {motoristHasCrlv(riskAnalysis.motorist.attachments) ? (
                      <AlertDescription>
                        <Button
                          variant="outline"
                          colorScheme="green"
                          ml="4"
                          size="sm"
                          onClick={() => {
                            const documentUrl = motoristHasCrlv(
                              riskAnalysis.motorist.attachments,
                            )?.attachment_file_url
                            window.open(documentUrl, '_blank')
                          }}
                        >
                          Visualizar documento
                        </Button>
                      </AlertDescription>
                    ) : null}
                  </Alert>
                </GridItem>
              </Grid>

              <Divider my="4" />

              <Heading size="md" fontWeight="normal" mb="5">
                Informações sobre o veículo
              </Heading>

              <Grid templateColumns="repeat(12, 1fr)" gap="3">
                <GridItem colSpan={[12, 6, 3]} mr={2}>
                  <Select
                    label="Rastreador?"
                    name="is_tracker"
                    setValue={setValue}
                    error={errors.is_tracker}
                    initialValue={riskAnalysis.vehicle.has_tracker ? 'yes' : 'no'}
                    options={[
                      {
                        label: 'Não',
                        value: 'no',
                      },
                      {
                        label: 'Sim',
                        value: 'yes',
                      },
                    ]}
                  />
                </GridItem>

                <GridItem colSpan={[12, 6, 3]} mr={2}>
                  <Select
                    label="Localizador?"
                    name="is_locator"
                    setValue={setValue}
                    error={errors.is_locator}
                    initialValue="no"
                    options={[
                      {
                        label: 'Não',
                        value: 'no',
                      },
                      {
                        label: 'Sim',
                        value: 'yes',
                      },
                    ]}
                  />
                </GridItem>

                <GridItem colSpan={[12, 6, 3]} mr={2}>
                  <Select
                    label="ANTT ativa?"
                    name="is_antt_active"
                    setValue={setValue}
                    error={errors.is_antt_active}
                    initialValue="no"
                    options={[
                      {
                        label: 'Não',
                        value: 'no',
                      },
                      {
                        label: 'Sim',
                        value: 'yes',
                      },
                    ]}
                  />
                </GridItem>

                <GridItem colSpan={[12, 6, 3]} mr={2}>
                  <Select
                    label="Inspeção?"
                    name="is_inspection"
                    setValue={setValue}
                    error={errors.is_inspection}
                    initialValue="no"
                    options={[
                      {
                        label: 'Não',
                        value: 'no',
                      },
                      {
                        label: 'Sim',
                        value: 'yes',
                      },
                    ]}
                  />
                </GridItem>
              </Grid>

              <Divider my="4" />

              <Heading size="md" fontWeight="normal" mb="2">
                Checklists de segurança
              </Heading>

              <Grid templateColumns="repeat(12, 1fr)" gap="3">
                <GridItem colSpan={[12, 6, 12]}>
                  <Accordion allowToggle>
                    {checklistRiskAnalysis && checklistRiskAnalysis.length > 0 ? (
                      checklistRiskAnalysis?.map(checklist => (
                        <AccordionItem key={checklist?.id} border="none" mb={2}>
                          <AccordionButton
                            _focus={{
                              outline: 'none',
                              boxShadow: 'none',
                            }}
                            padding={3}
                            borderRadius={6}
                            borderWidth={1}
                            justifyContent="space-between"
                          >
                            <Flex alignItems="center">
                              <Text fontWeight="bold">Tipo de checagem:</Text>
                              <Badge fontSize="small" colorScheme="gray" ml={1}>
                                {checklist?.checkType}
                              </Badge>
                            </Flex>
                            <AccordionIcon />
                          </AccordionButton>
                          <AccordionPanel borderWidth={1} mt="2" borderRadius={6} p={3}>
                            <Flex alignItems="center">
                              <Text fontWeight="bold">Status:</Text>
                              <Badge
                                fontSize="small"
                                colorScheme={
                                  checklist.status === 'Aprovado'
                                    ? 'green'
                                    : checklist.status === 'Reprovado'
                                    ? 'red'
                                    : 'orange'
                                }
                                ml={1}
                              >
                                {checklist?.status}
                              </Badge>
                            </Flex>
                            {checklist.comments && (
                              <Flex alignItems="center" mt={1}>
                                <Text>
                                  <b>Comentários adicionais</b>: {checklist.comments}
                                </Text>
                              </Flex>
                            )}
                          </AccordionPanel>
                        </AccordionItem>
                      ))
                    ) : (
                      <>Nenhum checklist encontrado no SpotHub.</>
                    )}
                  </Accordion>
                </GridItem>
              </Grid>

              <Divider mb="4" mt={2} />

              <Grid templateColumns="repeat(12, 1fr)" gap="3">
                <GridItem colSpan={[12, 6, 12]}>
                  <TextArea
                    name="reason"
                    error={errors.reason}
                    h="150"
                    label="Motivo da solicitação de liberação"
                    setValue={setValue}
                  />
                </GridItem>

                <GridItem colSpan={[12, 6, 12]}>
                  <Divider />
                </GridItem>

                <GridItem colSpan={[12, 6, 4]}>
                  <Select
                    label="Adicionar Observações Adicionais?"
                    name="addObservations"
                    setValue={setValue}
                    options={[
                      {
                        label: 'Não',
                        value: 'no',
                      },
                      {
                        label: 'Sim',
                        value: 'yes',
                      },
                    ]}
                    onSelectOption={option => {
                      setWannaAddObservations(option.value)
                    }}
                  />
                </GridItem>

                {wannaAddObservations === 'yes' && (
                  <GridItem colSpan={[12, 6, 12]}>
                    <TextArea name="observations" h="150" label="Observações" setValue={setValue} />
                  </GridItem>
                )}
              </Grid>

              <Divider my="4" />

              <Grid templateColumns="repeat(12, 1fr)" gap="3">
                <GridItem colSpan={[12, 6, 12]}>
                  <DropzoneImageUpload
                    name="attachment_files"
                    setValue={setValue}
                    displayMode="grid"
                    multiple
                    label="Anexo de exporádicos"
                    maxFiles={10}
                  />
                </GridItem>
              </Grid>

              <ModalFooter>
                <Button colorScheme="blue" mr={3} onClick={onClose}>
                  Cancelar
                </Button>
                <Button colorScheme="green" type="submit" isLoading={isLoadingPostNotifyRiskAnalysis}>
                  Enviar notificação
                </Button>
              </ModalFooter>
            </form>
          </Box>
        </ModalContent>
      </Modal>
    </>
  )
}
