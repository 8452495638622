import { Flex, FlexProps, Text } from '@chakra-ui/react'
import { GiCommercialAirplane } from 'react-icons/gi'
import { FreightType } from '../../services/types'

interface FreightTypeProps extends FlexProps {
  freight: FreightType
  noTitle?: boolean
  fontSizeIcon?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl' | number
}

export function FreightTypeComponent({
  freight,
  color = 'blue.500',
  noTitle = false,
  fontSize = 'sm',
  fontSizeIcon = 'xs',
  ...rest
}: FreightTypeProps): JSX.Element {
  return (
    <>
      {freight.type === 'aerial' && (
        <Flex fontSize={fontSize} color={color} {...rest}>
          {!noTitle && <Text mr={2}>Aéreo</Text>}
          <Flex mt="0.5" color={color} fontSize={fontSizeIcon} {...rest}>
            <GiCommercialAirplane />
          </Flex>
        </Flex>
      )}
    </>
  )
}
