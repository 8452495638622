import {
  useToast,
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Skeleton,
  useDisclosure,
  ModalFooter,
  Center,
  Spinner,
} from '@chakra-ui/react'
import { useState } from 'react'
import { RiArrowLeftLine } from 'react-icons/ri'
import { useHistory } from 'react-router-dom'
import { queryClient } from '../../../../../config/react-query'
import { useIsBlockEntity } from '../../../../../services/endpoints/blocked-entities/isBlockEntity'
import { QualpRoute } from '../../../../../services/endpoints/qualp/postPlanRoute'
import { usePostRoutePoints } from '../../../../../services/endpoints/truckpad/usePostRoutePoints'
import { calculateTotalToll } from '../../services/calculate-total-toll'
import { decodePolyline } from '../../services/decode-polyline-service'
import RoutePlannerMapComponentQualp from '../RoutePlannerMapComponentQualp'
import RouteDiretion from '../components/Diretion'
import { RouteCard } from '../components/Route'
import { useQueryParams } from '../../../../../hooks/useQueryParams'
import { useAppDispatch } from '../../../../../store'
import { setRoutePointsHasPendencies } from '../../../../../store/slices/freightPendenciesSlice'
import { toastError } from '../../../../../config/error/toastError'

interface IRoutePlannerModalQualpProps {
  isOpen: boolean
  onClose: () => void
  routes: any[]
  origin: any
  destination: any
  setTollValue?: (value: number) => void
  setDistance?: (value: number) => void
  setRouteResponsePoints?: (value: any) => void
  freight_id?: string
  tag_provider?: string
  freightTollValue?: number
  freight_distance?: number
  truckCategory?: number
  freight_category?: string
  cargo_type?: string
}

export function RoutePlannerModalQualp({
  isOpen,
  onClose,
  routes,
  origin,
  destination,
  setTollValue,
  setDistance,
  setRouteResponsePoints,
  freight_id,
  tag_provider = 'qualp',
  freightTollValue = undefined,
  freight_distance,
  truckCategory,
  freight_category,
  cargo_type,
}: IRoutePlannerModalQualpProps): JSX.Element {
  const dispatch = useAppDispatch()
  const toast = useToast()
  const history = useHistory()
  const { isOpen: isConfirmOpen, onOpen: onConfirmOpen, onClose: onConfirmClose } = useDisclosure()
  const [clickedRoute, setClickedRoute] = useState<string>('')
  const [selectedRoute, setSelectedRoute] = useState<QualpRoute | null>(null)
  const { data: isBlockedEntity } = useIsBlockEntity({
    freight_id: freight_id || undefined,
  })
  const isPageFreight = useQueryParams('isFreightPage')
  const { mutateAsync: createRoutePoints, isLoading } = usePostRoutePoints({
    onSuccess: () => {
      queryClient.invalidateQueries('find-route-points')
      queryClient.invalidateQueries('check-pendencies')
    },
  })

  const handleRouteSelection = async (route: QualpRoute) => {
    if (!route || !route.tolls) return

    const coordinates = decodePolyline(route.polyline)
    const tollValue = calculateTotalToll(route.tolls, truckCategory)
    const routeDistance = route.distance

    const processRouteSelection = () => {
      if (setTollValue && setDistance) {
        setTollValue(tollValue)
        setDistance(+routeDistance)
      }
      setSelectedRoute(route)
    }

    const showToast = (title: string, description: string, status: 'success' | 'warning') => {
      toast({
        title,
        description,
        status,
        duration: status === 'success' ? 3000 : 8000,
        isClosable: true,
        position: 'top',
      })
    }

    if (freight_id && !isBlockedEntity) {
      if (tag_provider && !isBlockedEntity && !isPageFreight) {
        try {
          showToast('Rota Selecionada', 'Rota selecionada com sucesso!', 'success')

          await createRoutePoints({
            points: coordinates.map((point: { lat: number; lng: number }) => ({
              latitude: point.lat,
              longitude: point.lng,
            })),
            freight_id,
            toll_value: tollValue,
            provider: 'qualp',
            distance: routeDistance,
          })
          history.goBack()
          processRouteSelection()

          // Exibe o modal de confirmação após selecionar a rota
          onConfirmOpen()
        } catch (error) {
          toastError({ toast, error })
        }
      } else if (isPageFreight) {
        showToast(
          'Consulta de Rota',
          'Rota não selecionada, não é possível selecionar a rota pela tela de frete.',
          'warning',
        )
        setSelectedRoute(route)
      } else {
        showToast(
          'Consulta de Rota',
          'Rota não selecionada, verifique se o frete está bloqueado ou se a placa e a tag estão preenchidas.',
          'warning',
        )
        setSelectedRoute(route)
      }
    } else {
      processRouteSelection()
    }
  }

  const renderMapWithPolyline = (route: QualpRoute) => {
    if (!route.simplified_polyline) return null

    const decodedPolyline = decodePolyline(route.simplified_polyline)

    if (decodedPolyline.length === 0) return null

    return (
      <RoutePlannerMapComponentQualp
        showIcons
        zoom={8}
        origin={{
          cityName: selectedRoute?.start_address || 'Cidade de Origem',
          lat: String(decodedPolyline[0].lat),
          lng: String(decodedPolyline[0].lng),
        }}
        destination={{
          cityName: selectedRoute?.end_address || 'Cidade de Destino',
          lat: String(decodedPolyline[decodedPolyline.length - 1].lat),
          lng: String(decodedPolyline[decodedPolyline.length - 1].lng),
        }}
        polyline={selectedRoute?.polyline}
      />
    )
  }

  return (
    <>
      {isOpen && (
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          isCentered
          closeOnOverlayClick={false}
          size="6xl"
          scrollBehavior="inside"
        >
          <ModalOverlay />
          <ModalContent>
            {isLoading ? (
              <Center justifyContent="center" p={4} h="100%" w="100%" gridGap={4}>
                Carregando pontos de rota, aguarde...
                <Spinner size="lg" />
              </Center>
            ) : (
              <>
                <ModalBody overflowY="hidden" p={0}>
                  <Flex direction={{ base: 'column', lg: 'row' }}>
                    <Flex flexDir="column">
                      <Flex alignItems="center" gridGap="2" my={4} mx={4} position="fixed">
                        <Button size="md" variant="solid" p="0" onClick={onClose}>
                          <RiArrowLeftLine />
                        </Button>
                        <Heading as="h2" size="md">
                          Rotas
                        </Heading>
                      </Flex>
                      <Box flex="0 1 500px" overflowY="auto" p={4} position="relative" mt={16}>
                        <Heading as="h2" size="md">
                          Clique em uma das rotas para selecionar
                        </Heading>
                        <Divider my={4} />
                        <RouteDiretion origin={origin} destination={destination} />

                        <Divider my={4} />
                        <Flex flexDir="column" gridGap={4}>
                          {!isPageFreight &&
                            routes &&
                            routes.map((route, i) => (
                              <Skeleton isLoaded={!isLoading}>
                                <Box key={route[0]?.distance || i} borderBottom="1px" borderColor="gray.700">
                                  <RouteCard
                                    key={route[0]?.distance || i}
                                    isSelected={clickedRoute === `route ${i + 1}`}
                                    id={i + 1}
                                    route={route}
                                    handleRouteSelection={() => {
                                      handleRouteSelection(route)
                                      setClickedRoute(`route ${i + 1}`)
                                    }}
                                    onMouseEnter={() => {
                                      setSelectedRoute(route)
                                      if (setRouteResponsePoints) {
                                        setRouteResponsePoints(route)
                                      }
                                    }}
                                    truckCategory={truckCategory}
                                  />
                                </Box>
                              </Skeleton>
                            ))}
                          {isPageFreight && routes && routes.length > 0 && (
                            <Box borderBottom="1px" borderColor="gray.700">
                              <RouteCard
                                isSelected
                                id={1}
                                route={routes[0]}
                                handleRouteSelection={() => {
                                  handleRouteSelection(routes[0][0])
                                  setClickedRoute(`route 1`)
                                }}
                                onMouseEnter={() => {
                                  setSelectedRoute(routes[0][0])
                                  if (setRouteResponsePoints) {
                                    setRouteResponsePoints(
                                      routes[0][0]?.route_points.map((point: any) => ({
                                        position: { lat: point[1], lng: point[0] },
                                      })) || [],
                                    )
                                  }
                                }}
                                truckCategory={truckCategory}
                                antt_value={
                                  routes[0].freight_table ? routes[0].freight_table.dados : undefined
                                }
                                freight_category={freight_category}
                                cargo_type={cargo_type}
                                freightTollValue={freightTollValue}
                                freight_distance={String(freight_distance) || undefined}
                                isPageFreight
                              />
                            </Box>
                          )}
                        </Flex>
                      </Box>
                    </Flex>
                    <Box flex="2" h="calc(100vh - 72px)" overflowY="auto" position="sticky" top="0">
                      {selectedRoute && !isPageFreight && (
                        <Box width="100%" height="100%">
                          {renderMapWithPolyline(selectedRoute)}
                        </Box>
                      )}
                    </Box>
                  </Flex>
                </ModalBody>
              </>
            )}
          </ModalContent>
        </Modal>
      )}

      <Modal isOpen={isConfirmOpen} onClose={onConfirmClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirmar</ModalHeader>
          <ModalBody>
            <Text>Deseja voltar para a tela de pendências?</Text>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={async () => {
                await queryClient.invalidateQueries('check-pendencies')
                dispatch(setRoutePointsHasPendencies(false))
                history.goBack()
              }}
            >
              Sim
            </Button>
            <Button variant="ghost" onClick={onConfirmClose}>
              Não
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
