import { Alert, AlertIcon, AlertTitle, Flex, useToast } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback, useEffect, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { RiUser2Fill } from 'react-icons/ri'
import { AlertModal } from '../../../../components/AlertModal'
import { toastError } from '../../../../config/error/toastError'
import { queryClient } from '../../../../config/react-query'
import { apiServer } from '../../../../services/api'
import { useIsBlockEntity } from '../../../../services/endpoints/blocked-entities/isBlockEntity'
import { ownerPayload, useCreateOwner } from '../../../../services/endpoints/owners/createOwner'
import { useCreateAttachmentToOwner } from '../../../../services/endpoints/owners/owners-attachments/createVehicleAttachment'
import { usePutVehicle } from '../../../../services/endpoints/vehicles/putVehicle'
import OwnerType from '../../../../services/types/OwnerType'
import getOnlyNumbers from '../../../../services/utils/getOnlyNumbers'
import { useAppDispatch, useAppSelector } from '../../../../store'
import { setVehicleOwner } from '../../../../store/slices/freightPendenciesSlice'
import {
  setDriverLicenseFile,
  setDriverLicenseOwnerImg,
  setIsNewImage,
} from '../../../../store/slices/ocrCnhSlice'
import { verifyIfDataByMotorist } from '../utils/validations'
import ChooseOwner from '../components/ChooseOwner'
import { DrawerWrapper } from '../components/DrawerWrapper'
import { OwnerSearch } from '../components/OwnerSearch'
import {
  onwerSchema,
  OwnerForm,
  ownerFormInitialValues,
  OwnerFormValues,
} from '../../../../components/forms/owner'
import { createCNHInitialValues, createOwnerInitialValues } from '../utils'
import UploadCNH from './UploadCnh.modal'

type OwnerModalProps = {
  isOpen: boolean
  onClose: () => void
}

const OwnerModalForm = ({ isOpen, onClose }: OwnerModalProps): JSX.Element => {
  const [ownerAlreadyFound, setOwnerAlreadyFound] = useState<OwnerType | null>()
  const { owner, vehicle } = useAppSelector(state => state.freightPendenciesSlice)
  const { driverLicenseFile, isNewImage } = useAppSelector(state => state.ocrCnhSlice)
  const toast = useToast()
  const dispatch = useAppDispatch()

  const invalidateQueries = () => {
    queryClient.invalidateQueries('vehicle')
    queryClient.invalidateQueries('check-pendencies')
  }

  const methods = useForm<OwnerFormValues>({
    mode: 'onChange',
    defaultValues: ownerFormInitialValues,
    resolver: yupResolver(onwerSchema),
  })

  const {
    handleSubmit,
    formState: { isDirty, isSubmitting },
    getValues,
    reset,
  } = methods

  const showSuccessToast = (message: string) => {
    toast({
      status: 'success',
      title: message,
      position: 'top-right',
      duration: 8000,
      isClosable: true,
    })
  }

  const createOwner = useCreateOwner({
    onError: errorReq => {
      toastError({
        error: errorReq,
        toast,
      })
    },
  })

  const createAttachment = useCreateAttachmentToOwner({
    onSuccess: () => {
      showSuccessToast('Anexo salvo com sucesso!')
      dispatch(setDriverLicenseFile({} as File))
      dispatch(setIsNewImage(false))
    },
    onError: errorReq => {
      toastError({
        error: errorReq,
        toast,
      })
    },
  })

  const updateVehicle = usePutVehicle({
    onSuccess: () => {
      showSuccessToast('cadastro do frete atualizado!')
      invalidateQueries()
    },
    onError: errorReq => {
      toastError({
        error: errorReq,
        toast,
      })
    },
  })

  // Get data for blocked entities
  const { data: blockedEntity } = useIsBlockEntity({
    vehicle_owner_id: ownerAlreadyFound?.id,
  })

  // Function to show a warning toast when linking an owner to a vehicle
  function notifyOnlyLinkOwnerToVehicle(): void {
    toast({
      title:
        'Proprietário está em análise e portanto não foi editado, apenas vinculado. Para alterações solicite correção da análise de risco.',
      isClosable: true,
      status: 'warning',
      position: 'top',
      duration: 1000 * 8,
    })
  }

  // Function to link owner to vehicle
  async function linkOwnerToVehicle(owner_id: string): Promise<void> {
    if (vehicle && owner_id) {
      await updateVehicle.mutateAsync({
        id: vehicle.id,
        owner_id,
        disable_entities_block: true,
      })
    }
  }

  // Function to unlink owner from vehicle
  async function UnlinkOwnerFromVehicle() {
    if (!vehicle) return
    try {
      await updateVehicle.mutateAsync({
        id: vehicle.id,
        owner_id: null as any,
        disable_entities_block: true,
      })
      dispatch(setVehicleOwner(null))
      reset(ownerFormInitialValues)
      setOwnerAlreadyFound(null)
    } catch (error) {
      toastError({ toast, error })
    }
  }

  async function attachDoc(Owner?: OwnerType): Promise<void> {
    if (driverLicenseFile.name && Owner?.id && ownerAlreadyFound) {
      const hasAttachment = ownerAlreadyFound?.attachments?.find(attachment => attachment.type === 'cnh')
      await createAttachment.mutateAsync({
        ...(hasAttachment?.id ? { id: hasAttachment.id } : {}),
        attachment_file: driverLicenseFile,
        owner_id: Owner.id,
        type: 'cnh',
      })
    }
  }

  const onSubmit: SubmitHandler<OwnerFormValues> = async data => {
    if (blockedEntity && ownerAlreadyFound) {
      try {
        await linkOwnerToVehicle(ownerAlreadyFound.id)
        notifyOnlyLinkOwnerToVehicle()
        invalidateQueries()
        onClose()
      } catch (error) {
        toastError(error)
      } finally {
        reset(data)
      }
      return
    }

    try {
      const payload = {
        type: data.type,
        name: data.name,
        ...(data.type === 'pf'
          ? {
              birth: data.birth,
              cpf: getOnlyNumbers(data.document),
              rg: data.rg_ie,
              rg_dispatch_date: data.rg_dispatch_date,
              rg_dispatcher: data.rg_dispatcher,
              rg_uf: data.rg_uf,
              father_name: data.father_name,
              mother_name: data.mother_name,
            }
          : {
              cnpj: getOnlyNumbers(data.document),
              ie: data.rg_ie,
            }),
        phone: getOnlyNumbers(data.phone),
        address: data.address,
        address_number: data.address_number,
        district: data.district,
        city_id: data?.city.value,
        zipcode: getOnlyNumbers(data.zipcode),
      } as ownerPayload

      if (ownerAlreadyFound) {
        const isOwnerMotoristToo = verifyIfDataByMotorist(ownerAlreadyFound)
        if (isOwnerMotoristToo) {
          try {
            const { data: response } = await apiServer.post('/create-owner-by-motorist', {
              motorist_id: ownerAlreadyFound?.id,
              ...payload,
            })
            showSuccessToast('Proprietário criado com sucesso!')
            await attachDoc(response.data)
            await linkOwnerToVehicle(response.data.id)
            onClose()
            invalidateQueries()
          } catch (error) {
            toastError({ toast, error })
          } finally {
            reset(data)
          }
          return
        }
      }

      if (ownerAlreadyFound?.id) {
        const owner_id = ownerAlreadyFound.id
        await createOwner.mutateAsync({
          ...payload,
          id: owner_id,
        })
        showSuccessToast('Proprietário atualizado com sucesso!')
        await attachDoc(ownerAlreadyFound)
        await linkOwnerToVehicle(owner_id)
      } else {
        const { data: ownerCreated } = await createOwner.mutateAsync({
          ...payload,
        })
        showSuccessToast('Proprietário criado com sucesso!')
        await attachDoc(ownerCreated)
        await linkOwnerToVehicle(ownerCreated.id)
      }
      invalidateQueries()
      onClose()
    } catch (error) {
      toastError(error)
    } finally {
      reset(data)
    }
  }

  // Function to handle closing the modal and warn the user if there are unsaved changes
  const handleClose = () => {
    if (isDirty) {
      toast({
        title: 'Alterações não salvas',
        description: 'Por favor salve as alterações antes de fechar ou descarte as alterações.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      })
      return
    }

    if (isNewImage && driverLicenseFile.name) {
      toast({
        title: 'Alterações não salvas, nova cnh',
        description: 'Por favor salve as alterações antes de fechar ou descarte as alterações.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      })
      return
    }

    onClose()
  }

  // Function to create initial values for the form
  const crateInitialValues = useCallback(
    (ownerValues: OwnerType) => {
      const { attachments } = ownerValues
      dispatch(
        setDriverLicenseOwnerImg(
          attachments?.find(attachment => attachment.type === 'cnh')?.attachment_file_url || '',
        ),
      )
      reset(createOwnerInitialValues(ownerValues))
    },
    [reset, dispatch],
  )

  // Effect to initialize form values when a vehicle is present

  useEffect(() => {
    if (owner) {
      setOwnerAlreadyFound(owner)
      crateInitialValues(owner)
    }
  }, [owner, reset, crateInitialValues])

  return (
    <DrawerWrapper
      isOpen={isOpen}
      onClose={handleClose}
      onForceClose={() => {
        onClose()
        dispatch(setDriverLicenseFile({} as File))
        dispatch(setIsNewImage(false))
      }}
      title="Proprietário do veículo"
      onSave={() => handleSubmit(onSubmit)()}
      isSubmitting={isSubmitting}
      showForceClose={isDirty}
    >
      <OwnerSearch
        onSelect={data => {
          if (data.owner) {
            crateInitialValues(data.owner)
            setOwnerAlreadyFound(data.owner)
          }
        }}
      />

      <UploadCNH
        type="owner"
        onSaveInformations={data => {
          reset({
            ...getValues(),
            ...createCNHInitialValues(data),
          })
        }}
      />
      <Flex justify="space-between" gridGap={2} my={4} flexDirection={{ base: 'column', md: 'row' }}>
        <ChooseOwner
          type="owner"
          onOwnerFound={data => {
            const hasValues = Object.values(data)
            if (hasValues.length) {
              setOwnerAlreadyFound(data)
              crateInitialValues(data)
            } else {
              reset(ownerFormInitialValues)
            }
          }}
        />

        {owner?.id && (
          <AlertModal
            size="sm"
            titleButton="Desvincular proprietário"
            title="Desvincular Proprietário"
            description={`Tem certeza que deseja desvincular ${owner?.name} ?`}
            colorScheme="red"
            disabled={owner?.id !== ownerAlreadyFound?.id}
            isLoading={updateVehicle.isLoading}
            leftIcon={<RiUser2Fill />}
            onConfirm={UnlinkOwnerFromVehicle}
          />
        )}
      </Flex>
      {blockedEntity && (
        <Alert variant="subtle" status="error" borderRadius="md">
          <AlertIcon />
          <AlertTitle mr={2} fontSize="sm">
            Edição bloqueada. Solicite correção da análise de risco
          </AlertTitle>
        </Alert>
      )}

      <FormProvider {...methods}>
        <OwnerForm
          disabled={blockedEntity}
          hasOwner={!!owner?.id}
          onOwnerAlreadyFound={data => {
            if (data) {
              crateInitialValues(data)
              setOwnerAlreadyFound(data)
              return
            }

            setOwnerAlreadyFound(null)
          }}
        />
      </FormProvider>
    </DrawerWrapper>
  )
}

export default OwnerModalForm
