import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Icon,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react'
import { GrDocumentPdf } from 'react-icons/gr'
import { useEffect } from 'react'
import { CiotFormatted, useGetCiots } from '../../../../../services/endpoints/documents/ciot/list-ciots'
import { FormattedOneFreight } from '../../../../../services/endpoints/freights/getOneFreight'
import { getCiotPdfByCiotNumber } from '../../../../../services/utils/getCiotPdfByCiotNumber'
import { toastError } from '../../../../../config/error/toastError'
import { displayAdaptable } from '../../../../../services/utils/tableAdaptable'

interface ListCiotProps {
  freight: FormattedOneFreight
}

export function ListCiotAtFreight({ freight }: ListCiotProps): JSX.Element | null {
  const {
    data: ciots,
    isLoading,
    isError,
    error,
  } = useGetCiots({
    per_page: 20,
    freight_id: freight.id,
  })

  const bg = useColorModeValue('white', 'gray.800')
  const textColor = useColorModeValue('gray.800', 'white')

  const toast = useToast()

  useEffect(() => {
    if (isError) {
      toastError({ toast, error })
    }
  }, [isError, error, toast])

  async function redirectToNewPageWithPdf(ciot: CiotFormatted): Promise<void> {
    const url = await getCiotPdfByCiotNumber({
      ciot_number: ciot.ciot_number,
      contractor_id: ciot.contractor_id,
    })

    if (url) {
      window.open(url, '_blank')
    } else {
      toast({
        title: 'Este arquivo não está mais disponível!',
        status: 'warning',
        position: 'top-right',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  return freight ? (
    <Box bg={bg} p="4" borderRadius="8" mt="4" maxW="100%" d={displayAdaptable}>
      <Flex justify="space-between" align="center">
        <Heading fontSize="md" fontWeight="medium">
          CIOTs
        </Heading>
      </Flex>

      <Divider my="4" />

      {isLoading ? (
        <Flex>
          <Spinner />
        </Flex>
      ) : ciots?.data && ciots.data.length > 0 ? (
        <Table mt={4} size="sm">
          <Thead>
            <Tr>
              <Th>Número</Th>
              <Th textAlign="center">Status</Th>
              <Th>Frete</Th>
              <Th>CTE</Th>
              <Th fontSize="xs" whiteSpace="nowrap">
                Ord. de coleta
              </Th>
              <Th fontSize="xs" whiteSpace="nowrap">
                Data Emissão
              </Th>
              <Th>Valor</Th>
              <Th />
            </Tr>
          </Thead>
          <Tbody>
            {ciots.data.map(ciot => (
              <Tr key={ciot.id}>
                <Td pt={4} pb={4} whiteSpace="nowrap">
                  {ciot.ciot_number}
                </Td>
                <Td whiteSpace="nowrap">
                  <Text
                    textAlign="center"
                    bg={
                      ciot.ciot_status?.status === 'cancelado'
                        ? 'red.400'
                        : ciot.ciot_status?.status === 'encerrado'
                        ? 'gray.400'
                        : bg
                    }
                    textColor={textColor}
                    p={2}
                    borderRadius="full"
                  >
                    {ciot.ciot_status?.status ? ciot.ciot_status?.status.toLocaleUpperCase() : 'ABERTO'}
                  </Text>
                </Td>
                <Td whiteSpace="nowrap">
                  {ciot.cte?.freight && (
                    <Flex gridGap={4}>
                      <Text fontSize="xs">#{ciot.cte?.freight.freight_number}</Text>
                    </Flex>
                  )}
                </Td>

                <Td whiteSpace="nowrap">{ciot.cte_number}</Td>
                <Td whiteSpace="nowrap" textAlign="center">
                  {ciot.collect_order_number}
                </Td>
                <Td whiteSpace="nowrap">{ciot.date_emission}</Td>
                <Td whiteSpace="nowrap">{ciot.gross_ciot_value}</Td>
                <Td>
                  <Tooltip label="Baixar CIOT em pdf" hasArrow placement="top">
                    <Button
                      size="xs"
                      colorScheme="orange"
                      rel="noreferrer"
                      // eslint-disable-next-line no-return-await
                      onClick={async () => await redirectToNewPageWithPdf(ciot)}
                    >
                      <Icon as={GrDocumentPdf} fontSize="18" />
                    </Button>
                  </Tooltip>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      ) : (
        <Flex justifyContent="center">
          <Text>Nenhum documento encontrado</Text>
        </Flex>
      )}
    </Box>
  ) : null
}
