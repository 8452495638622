import { debounce } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { handleCheckIfCnpjIsBlackListed } from '../pages/black-list/check-document-exists/handleCnpjIsBlackListed'
import { handleCheckIfCpfIsBlackListed } from '../pages/black-list/check-document-exists/handleCpfIsBlackListed'
import { findOwnerByCnpj } from '../services/endpoints/owners/findOwnerByCnpj'
import { findOwnerByCpf } from '../services/endpoints/owners/findOwnerByCpf'
import BlackListType from '../services/types/BlackListType'
import OwnerType from '../services/types/OwnerType'
import getOnlyNumbers from '../services/utils/getOnlyNumbers'
import { CnpjData, searchPersonByCnpj } from '../services/utils/searchPersonByCnpj'

export type useSearchOwnerSearchResult = {
  owner?: OwnerType
  cpnj_person?: CnpjData
  isBlackListed?: BlackListType
  person?: 'pf' | 'pj'
}

type useSearchOwnerProps = {
  onSuccess?: (data: useSearchOwnerSearchResult) => void
  onBlackList?: (data: BlackListType) => void
  onError?: (error: any) => void
}

const useSearchOwner = ({
  onSuccess,
  onBlackList,
  onError,
}: useSearchOwnerProps): {
  loading: boolean
  search: (search: string, person?: 'pf' | 'pj') => void
} => {
  const [loading, setLoading] = useState(false)

  const handleSearch = useRef(
    debounce(
      async (search: string, person?: 'pf' | 'pj') => {
        setLoading(true)
        const onlyNumbers = getOnlyNumbers(search)
        try {
          if (person === 'pf' && onlyNumbers.length === 11) {
            const isBlackListed = await handleCheckIfCpfIsBlackListed(onlyNumbers)
            if (isBlackListed) {
              onBlackList?.(isBlackListed)
              return
            }
            const findOwnerCpf = await findOwnerByCpf(onlyNumbers)

            onSuccess?.({
              owner: findOwnerCpf,
              person: 'pf',
            })

            return
          }

          if (person === 'pj' && onlyNumbers.length === 14) {
            const isBlackListed = await handleCheckIfCnpjIsBlackListed(onlyNumbers)

            if (isBlackListed) {
              onBlackList?.(isBlackListed)
              return
            }

            const findOwnerCnpj = await findOwnerByCnpj(onlyNumbers)
            const cnpj = await searchPersonByCnpj(onlyNumbers)

            onSuccess?.({
              owner: findOwnerCnpj || undefined,
              cpnj_person: cnpj || undefined,
              person: 'pj',
            })

            return
          }
        } catch (error) {
          onError?.(error)
        } finally {
          setLoading(false)
        }
      },
      500,
      {},
    ),
  ).current

  useEffect(() => {
    return () => {
      handleSearch.cancel()
    }
  }, [handleSearch])

  return { loading, search: handleSearch }
}

export default useSearchOwner
