import { BalanceRequestStatusEnum } from '../types/EnumTypes'

export function balanceRequestStatusLegend(status?: BalanceRequestStatusEnum): string {
  if (status === BalanceRequestStatusEnum.AWAITING_ANALYSIS)
    return 'Aguardando responsável realizar a análise'
  if (status === BalanceRequestStatusEnum.AWAITING_PAYMENT)
    return 'Aguardando o reponsável realizar o pagamento e emitir os comprovantes'
  if (status === BalanceRequestStatusEnum.PAID) return 'Pagamento e emissão do comprovantes realizado'
  if (status === BalanceRequestStatusEnum.FINISHED) return 'Solicitação de saldo finalizada'
  return 'Solicitação de adiantamento reprovada pelo responsável'
}
