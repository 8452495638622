import { Box, Flex, Icon, Link, Text, Tooltip, useColorModeValue, useDisclosure } from '@chakra-ui/react'
import { useMemo, useState } from 'react'
import { AiOutlineCalculator } from 'react-icons/ai'
import {
  RiBriefcaseLine,
  RiCheckboxCircleFill,
  RiFileSearchLine,
  RiTruckLine,
  RiUser2Line,
} from 'react-icons/ri'
import { DisableFreightModal } from '../../../pages/freights/edit/DisableFreightModal'
import { EditOrDuplicateFreightModal } from '../../../pages/freights/edit/EditOrDuplicateFreightModal'
import { FormattedAllFreights } from '../../../services/endpoints/freights/getFreights'
import { formattedTransporter } from '../../../services/utils/formattedTransporter'
import parseCurrency from '../../../services/utils/parseCurrency'
import { CheckFreightIsExpired } from '../../CheckFreightIsExpired'
import { FreightTypeComponent } from '../../freight-type/FreightTypeComponent'
import { Container } from './Container'
import { DefaultPropCards } from './DefaultPropCards'

interface KanbanCardActiveProps {
  freight: FormattedAllFreights
}

export function KanbanCardActive({ freight }: KanbanCardActiveProps): JSX.Element {
  const bg = useColorModeValue('gray.200', 'gray.500')
  const [actionFreightType, setActionFreightType] = useState<'edit' | 'duplicate'>('edit')

  const {
    isOpen: isDisableFreightModalOpen,
    onOpen: onDisableFreightModalOpen,
    onClose: onDisableFreightModalClose,
  } = useDisclosure()

  const {
    isOpen: isFreightFormModalOpen,
    onOpen: onFreightFormModalOpen,
    onClose: onFreightFormModalClose,
  } = useDisclosure()

  const contactName = useMemo(() => {
    if (freight.client_contact) {
      let name = freight.client_contact.name.split(' ')
      name = name.slice(0, 2)
      return name.join(' ')
    }
    return ''
  }, [freight.client_contact])

  return (
    <Container
      freight={freight}
      header={
        <DefaultPropCards
          freight={freight}
          type="active"
          bg={bg}
          setActionFreightType={setActionFreightType}
          onFreightFormModalOpen={onFreightFormModalOpen}
          onDisableFreightModalOpen={onDisableFreightModalOpen}
        />
      }
    >
      <Flex
        justifyContent="space-between"
        alignItems="flex-end"
        borderBottomColor={bg}
        borderBottomWidth={1}
        pb="0.5"
        mb="0.5"
      >
        <Text fontSize="xs">
          <Flex direction="row">
            <Icon as={RiUser2Line} fontSize="sm" mr="1" /> {freight.creator?.name?.split(' ')[0]}
          </Flex>
        </Text>

        {freight.agreed_price ? (
          <>
            <Text fontSize="xs">
              <Flex direction="row">
                <Icon as={RiTruckLine} fontSize="md" mr="1" />

                {
                  new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })
                    .format(freight.agreed_price)
                    .split(',')[0]
                }
              </Flex>
            </Text>
          </>
        ) : (
          <Text fontSize="xs">
            <Flex direction="row">
              <Icon as={RiFileSearchLine} fontSize="md" mr="1" />
              {Number(freight.suggested_price) > 0
                ? `${
                    freight.suggested_price &&
                    new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })
                      .format(freight.suggested_price)
                      .split(',')[0]
                  }`
                : 'A combinar'}
            </Flex>
          </Text>
        )}

        <Text fontSize="xs">
          <Flex direction="row">
            <Icon as={RiBriefcaseLine} fontSize="md" mr="1" />

            {freight.service_price && parseCurrency(freight.service_price)}
          </Flex>
        </Text>
      </Flex>
      {(freight.transporter || freight.calculation_ref) && (
        <Flex
          justifyContent="space-between"
          alignItems="flex-end"
          fontSize="xs"
          borderBottomColor={bg}
          borderBottomWidth={1}
          pb="0.5"
          mb="0.5"
        >
          {freight.transporter && <Text>{formattedTransporter(freight.transporter)}</Text>}
          {freight.calculation_ref && (
            <Tooltip label="Ir para a calculadora">
              <Text as={Link} href={freight.calculation_ref}>
                <Icon as={AiOutlineCalculator} color="orange" fontSize="md" mr="1" />
              </Text>
            </Tooltip>
          )}
        </Flex>
      )}

      {freight.client_contact && (
        <Flex fontSize="xs" borderBottomColor={bg} borderBottomWidth={1} pb="0.5" mb="0.5">
          <Text mr={2} fontWeight="bold">
            Solicitante:
          </Text>
          {contactName}
        </Flex>
      )}

      <Flex justifyContent="space-between" alignItems="center">
        <Box>
          <FreightTypeComponent freight={freight} />
        </Box>
        <Box>
          {freight.is_fretebras_sync && (
            <Tooltip hasArrow label="Frete publicado">
              <Flex fontSize="xs" fontWeight="bold" cursor="pointer" alignItems="center">
                <Flex color="green.400">
                  <Icon as={RiCheckboxCircleFill} fontSize="md" mr="1" />
                </Flex>
              </Flex>
            </Tooltip>
          )}
        </Box>
        <Box>
          <CheckFreightIsExpired freight={freight} />
        </Box>
      </Flex>
      <DisableFreightModal
        freight_ids={[String(freight?.id)]}
        isOpen={isDisableFreightModalOpen}
        onClose={() => {
          onDisableFreightModalClose()
        }}
      />
      {freight && (
        <>
          <EditOrDuplicateFreightModal
            type={actionFreightType}
            freight_id={freight.id}
            isOpen={isFreightFormModalOpen}
            onClose={() => {
              onFreightFormModalClose()
            }}
          />
        </>
      )}
    </Container>
  )
}
