import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'
import { queryClient } from '../../../config/react-query'
import { apiServer } from '../../api'
import { CteIssuanceRequestStatusEnum } from '../../types/EnumTypes'

type UpdateCteIssuanceRequestType = {
  id: string
  data: {
    has_toll?: boolean
    toll_value?: number
    deduct_motorist_total?: boolean
    status?: CteIssuanceRequestStatusEnum
    reject_reason?: string | null
    motorist_id?: string
    motorist_vehicle_id?: string
    observation?: string | null
    tag_toll?: string
    discount_buonny?: number
  }
}

async function updateCteIssuanceRequest(data: UpdateCteIssuanceRequestType) {
  const response = await apiServer.put(`/cte-issuance-requests/${data.id}`, data.data)
  queryClient.invalidateQueries('cte-issuance-requests')
  return response
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function usePutCteIssuanceRequest(
  options?: UseMutationOptions<AxiosResponse, unknown, UpdateCteIssuanceRequestType, unknown>,
) {
  return useMutation(async (data: UpdateCteIssuanceRequestType) => updateCteIssuanceRequest(data), options)
}
