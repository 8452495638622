import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'
import { queryClient } from '../../../config/react-query'
import { apiServer } from '../../api'
import { BalanceRequestStatusEnum } from '../../types/EnumTypes'

type UpdateBalanceRequestType = {
  id: string
  data: {
    total_value?: number
    account_digit?: string | null
    account?: string | null
    agency?: string | null
    account_type?: string | null
    bank?: string | null
    pix?: string | null
    payment_type?: string | null
    e_frete?: boolean
    discount_buonny?: number
    cte_id?: string
    status?: BalanceRequestStatusEnum
    reject_reason?: string | null
    observation?: string | null
    payment_observation?: string | null
  }
}

async function updateBalanceRequest(data: UpdateBalanceRequestType) {
  const response = await apiServer.put(`/balance-requests/${data.id}`, data.data)
  queryClient.invalidateQueries('balance-requests')
  return response
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function usePutBalanceRequest(
  options?: UseMutationOptions<AxiosResponse, unknown, UpdateBalanceRequestType, unknown>,
) {
  return useMutation(async (data: UpdateBalanceRequestType) => updateBalanceRequest(data), options)
}
